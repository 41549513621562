const latestDataActions = {
  GET_LATEST_DATA: 'GET_LATEST_DATA',
  GET_LATEST_DATA_SUCCESS: 'GET_LATEST_DATA_SUCCESS',
  GET_LATEST_DATA_ERROR: 'GET_LATEST_DATA_ERROR',
  GET_LATEST_DATA_CLEAN_STATE: 'GET_LATEST_DATA_CLEAN_STATE',
};

const getLatestData = (filters) => ({
  type: latestDataActions.GET_LATEST_DATA,
  payload: filters,
});

const getLatestDataSuccess = (data) => ({
  type: latestDataActions.GET_LATEST_DATA_SUCCESS,
  payload: data
});

const getLatestDataError = ({ message }) => ({
  type: latestDataActions.GET_LATEST_DATA_ERROR,
  payload: message
});

const getLatestDataCleanState = () => ({
  type: latestDataActions.GET_LATEST_DATA_CLEAN_STATE,
});

export {
  latestDataActions,
  getLatestData,
  getLatestDataSuccess,
  getLatestDataError,
  getLatestDataCleanState
};
