import { all, fork, takeLeading, delay, put, call } from 'redux-saga/effects';
import * as Actions from './actions';
import * as requests from '../../helpers/HTTPRequest';
import ApiConstants from '../../helpers/ApiConstants';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';

function* fetchUnits() {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}`;
    const apiRes = yield call(requests.getRequest, url, {});

    let apiResUpdated = apiRes;
    if (apiRes && apiRes.length > 0) {
      console.log('start', new Date());
      const devicesChannelsRequest = [];
      apiRes.forEach((device) => {
        devicesChannelsRequest.push(
          call(
            requests.getRequest,
            `${ApiConstants.BASE_URL}${ApiConstants.GET_DEVICE_CHANNELS.replace(
              '{:deviceId}',
              device.deviceId
            )}`,
            {}
          )
        );
      });
      const devicesChannels = yield all(devicesChannelsRequest);
      apiResUpdated = apiRes.map((device, index) => {
        return { ...device, channels: devicesChannels[index] };
      });
      console.log('end', new Date());
    }

    yield put(Actions.fetchUnitsSuccess(apiResUpdated));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.fetchUnitsError(e));
  }
}

function* saveUnit({ payload }) {
  const { deviceId, data } = payload;
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}/${deviceId}`;
    yield call(requests.patchRequest, url, data);
    yield put(Actions.saveUnitConfigSuccess());

    toastSuccess(
      TextConstants.Common.EditedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Device
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.saveUnitConfigError(e));
  } finally {
    yield delay(500);
    yield put(Actions.saveUnitConfigCleanState());
  }
}

function* addUnit({ payload: unitData }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}`;
    yield call(requests.postRequest, url, unitData);
    yield put(Actions.addUnitSuccess());

    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Device
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.addUnitError(e));
  } finally {
    yield delay(500);
    yield put(Actions.addUnitCleanState());
  }
}

function* deleteUnit({ payload: deviceId }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}/${deviceId}`;
    yield call(requests.deleteRequest, url);
    yield put(Actions.deleteUnitSuccess());
    toastSuccess(
      TextConstants.Common.DeletedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Device
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.deleteUnitError(e));
  } finally {
    yield delay(500);
    yield put(Actions.deleteUnitCleanState());
  }
}

function* fetchDEviceChannels({ payload: data }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${
      ApiConstants.BASE_URL
    }${ApiConstants.GET_DEVICE_CHANNELS.replace('{:deviceId}', data.deviceId)}`;
    const apiRes = yield call(requests.getRequest, url, {});

    yield put(Actions.fetchDeviceChannelsSuccess(apiRes));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.fetchDeviceChannelsError(e));
  }
}

export function* watchDataView() {
  yield takeLeading(Actions.unitsChannelsActions.FETCH_UNITS, fetchUnits);
  yield takeLeading(Actions.unitsChannelsActions.SAVE_UNIT_CONFIG, saveUnit);
  yield takeLeading(Actions.unitsChannelsActions.ADD_UNIT, addUnit);
  yield takeLeading(Actions.unitsChannelsActions.DELETE_UNIT, deleteUnit);
  yield takeLeading(
    Actions.unitsChannelsActions.FETCH_DEVICE_CHANNELS,
    fetchDEviceChannels
  );
}

export default function* rootSaga() {
  yield all([fork(watchDataView)]);
}
