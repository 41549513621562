export const USERS_MANAGEMENT_ACTION = {
  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  SAVE_USER: 'SAVE_USER',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  SAVE_USER_ERROR: 'SAVE_USER_ERROR',
  SAVE_USER_CLEAN_STATE: 'SAVE_USER_CLEAN_STATE',
  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERROR: 'ADD_USER_ERROR',
  ADD_USER_CLEAN_STATE: 'ADD_USER_CLEAN_STATE',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  DELETE_USER_CLEAN_STATE: 'DELETE_USER_CLEAN_STATE',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
  UPDATE_USER_STATUS_ERROR: 'UPDATE_USER_STATUS_ERROR',
  RESEND_INVITATION_MAIL: 'RESEND_INVITATION_MAIL',
  RESEND_INVITATION_MAIL_SUCCESS: 'RESEND_INVITATION_MAIL_SUCCESS',
  RESEND_INVITATION_MAIL_ERROR: 'RESEND_INVITATION_MAIL_ERROR',
};
// =========================================================================
export const getUsers = () => ({
  type: USERS_MANAGEMENT_ACTION.GET_USERS,
});
// =========================================================================
export const addUser = (data) => ({
  type: USERS_MANAGEMENT_ACTION.ADD_USER,
  payload: data,
});
export const addUserSuccess = () => ({
  type: USERS_MANAGEMENT_ACTION.ADD_USER_SUCCESS,
});
export const addUserError = ({ message }) => ({
  type: USERS_MANAGEMENT_ACTION.ADD_USER_ERROR,
  payload: message,
});
export const addUserCleanState = () => ({
  type: USERS_MANAGEMENT_ACTION.ADD_USER_CLEAN_STATE,
});
// =========================================================================
export const saveUser = (data) => ({
  type: USERS_MANAGEMENT_ACTION.SAVE_USER,
  payload: data,
});
export const saveUserSuccess = () => ({
  type: USERS_MANAGEMENT_ACTION.SAVE_USER_SUCCESS,
});
export const saveUserError = ({ message }) => ({
  type: USERS_MANAGEMENT_ACTION.SAVE_USER_ERROR,
  payload: message,
});
export const saveUserCleanState = () => ({
  type: USERS_MANAGEMENT_ACTION.SAVE_USER_CLEAN_STATE,
});
// =========================================================================
export const deleteUser = (data) => ({
  type: USERS_MANAGEMENT_ACTION.DELETE_USER,
  payload: data,
});
export const deleteUserSuccess = (data) => ({
  type: USERS_MANAGEMENT_ACTION.DELETE_USER_SUCCESS,
  payload: data,
});
export const deleteUserError = ({ message }) => ({
  type: USERS_MANAGEMENT_ACTION.DELETE_USER_ERROR,
  payload: message,
});
export const deleteUserCleanState = () => ({
  type: USERS_MANAGEMENT_ACTION.DELETE_USER_CLEAN_STATE,
});
// =========================================================================
// =========================================================================
export const updateUserStatus = (data) => ({
  type: USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS,
  payload: data,
});
export const updateUserStatusSuccess = (data) => ({
  type: USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS_SUCCESS,
  payload: data,
});
export const updateUserStatusError = ({ message }) => ({
  type: USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS_ERROR,
  payload: message,
});
// =========================================================================
// =========================================================================
export const resendInvitationMail = (data) => ({
  type: USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL,
  payload: data,
});
export const resendInvitationMailSuccess = (data) => ({
  type: USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL_SUCCESS,
  payload: data,
});
export const resendInvitationMailError = ({ message }) => ({
  type: USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL_ERROR,
  payload: message,
});
// =========================================================================
