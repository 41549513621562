import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { dashboardMocks } from '../dashboard/mocks'
import { LAST_DATA_ACTION } from './actions';

function* loadData() {
  const channelData = dashboardMocks.data;
  yield put({ type: LAST_DATA_ACTION.GET_DATA_SUCCESS, data: channelData });
}

export function* lastDataSaga() {
  yield takeLatest(LAST_DATA_ACTION.GET_DATA, loadData)
}

export default function* rootSaga() {
  yield all([fork(lastDataSaga)]);
}
