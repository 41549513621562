const CommonConstants = {
  COMPANY_TYPE: {
    MZ_MERCHANDISE: "MZ_MERCHANDISE",
    GENERAL: "GENERAL",
  },
  // CREATE_COMPANY_AND_ADMIN: 'CREATE_COMPANY_AND_ADMIN',
  // CREATE_COMPANY_ADMIN: 'CREATE_COMPANY_ADMIN',

  CREATE_STORE_AND_ADMIN: 'CREATE_STORE_AND_ADMIN',
  CREATE_STORE_ADMIN: 'CREATE_STORE_ADMIN',
  US_DISABLED_STATUS: 'disabled',
  US_ENABLED_STATUS: 'enabled',
  GATEWAY: {
    GATEWAY_ID_PATTERN: /^[a-zA-Z0-9:_-]+$/,
  },
};

export default CommonConstants;
