import produce from 'immer';
import { GROUP_STORES_ACTIONS } from '../groupStores/actions';
import { STORES_MANAGEMENT_ACTIONS } from './actions';

const initialState = {
  stores: [],
  loading: false,
  type: '',
};

const StoresManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case STORES_MANAGEMENT_ACTIONS.GET_STORES:
    case STORES_MANAGEMENT_ACTIONS.ADD_STORE:
    case STORES_MANAGEMENT_ACTIONS.UPDATE_STORE:
    case STORES_MANAGEMENT_ACTIONS.DELETE_STORE:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });

    case STORES_MANAGEMENT_ACTIONS.GET_STORES_SUCCESS:
      return produce(state, (draft) => {
        draft.stores = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    case STORES_MANAGEMENT_ACTIONS.ADD_STORE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.stores = [payload, ...state.stores];
      });
    case STORES_MANAGEMENT_ACTIONS.UPDATE_STORE_SUCCESS:
      return produce(state, (draft) => {
        draft.stores = state.stores.map((x) =>
          x.storeId === payload.storeId ? payload : x
        );
        draft.loading = false;
        draft.type = actionType;
      });
    case STORES_MANAGEMENT_ACTIONS.DELETE_STORE_SUCCESS:
      return produce(state, (draft) => {
        draft.stores = state.stores.filter(
          (x) => x.storeId !== payload
        );
        draft.loading = false;
        draft.type = actionType;
      });

    case STORES_MANAGEMENT_ACTIONS.GET_STORES_ERROR:
    case STORES_MANAGEMENT_ACTIONS.ADD_STORE_ERROR:
    case STORES_MANAGEMENT_ACTIONS.UPDATE_STORE_ERROR:
    case STORES_MANAGEMENT_ACTIONS.DELETE_STORE_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE_SUCCESS:
    case GROUP_STORES_ACTIONS.ADD_GROUP_STORES_SUCCESS:
      return produce(state, (draft) => {
        draft.stores = payload.stores || state.stores || [];
      });
    default:
      return state;
  }
};

export default StoresManagement;
