import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import { DEPARTMENTS_MANAGEMENT_ACTION } from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import { toastError } from 'src/common/helpers/Notifications';

function* getDepartments() {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEPARTMENTS_MANAGEMENT}`;
    const apiRes = yield call(requests.getRequest, url, {});
    yield put({
      type: DEPARTMENTS_MANAGEMENT_ACTION.GET_DEPARTMENTS_SUCCESS,
      payload: apiRes,
    });
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: DEPARTMENTS_MANAGEMENT_ACTION.GET_DEPARTMENTS_ERROR,
      payload: 'DEPARTMENTS_MANAGEMENT_ACTION.GET_DEPARTMENTS_ERROR',
    });
  }
}

export function* DepartmentsManagementSaga() {
  yield takeLatest(
    DEPARTMENTS_MANAGEMENT_ACTION.GET_DEPARTMENTS,
    getDepartments
  );
}

export default function* rootSaga() {
  yield all([fork(DepartmentsManagementSaga)]);
}
