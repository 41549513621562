const realtimeDataActions = {
  GET_REALTIME_DATA: 'GET_REALTIME_DATA',
  GET_REALTIME_DATA_SUCCESS: 'GET_REALTIME_DATA_SUCCESS',
  GET_REALTIME_DATA_ERROR: 'GET_REALTIME_DATA_ERROR',
  GET_REALTIME_DATA_CLEAN_STATE: 'GET_REALTIME_DATA_CLEAN_STATE',
};

const getRealtimeData = (filters) => ({
  type: realtimeDataActions.GET_REALTIME_DATA,
  payload: filters,
});

const getRealtimeDataSuccess = (data) => ({
  type: realtimeDataActions.GET_REALTIME_DATA_SUCCESS,
  payload: data
});

const getRealtimeDataError = ({ message }) => ({
  type: realtimeDataActions.GET_REALTIME_DATA_ERROR,
  payload: message
});

const getRealtimeDataCleanState = () => ({
  type: realtimeDataActions.GET_REALTIME_DATA_CLEAN_STATE,
});

export {
  realtimeDataActions,
  getRealtimeData,
  getRealtimeDataSuccess,
  getRealtimeDataError,
  getRealtimeDataCleanState
};
