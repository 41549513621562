import produce from 'immer';
import { LOGGERS_MANAGEMENT_ACTIONS } from './actions';

const initialState = {
  loggers: [],
  loading: false,
  type: '',
};

const LoggersManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS:
    case LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER:
    case LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER:
    case LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loggers = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    case LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_SUCCESS:
      return produce(state, (draft) => {
        draft.loggers = [payload, ...state.loggers];
        draft.loading = false;
        draft.type = actionType;
      });
    case LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_SUCCESS:
      return produce(state, (draft) => {
        draft.loggers = state.loggers.map((user) =>
          user.username === payload.username ? payload : user
        );
        draft.loading = false;
        draft.type = actionType;
      });
    case LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER_SUCCESS:
      return produce(state, (draft) => {
        draft.loggers = state.loggers.filter((x) => x.loggerId !== payload);
        draft.loading = false;
        draft.type = actionType;
      });

    case LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS_ERROR:
    case LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_ERROR:
    case LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_ERROR:
    case LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER_ERROR:
    case LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_CLEAN_STATE:
    case LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_CLEAN_STATE:
    case LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default LoggersManagement;
