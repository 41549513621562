import produce from 'immer';
import { GROUP_DEVICES_ACTIONS } from './actions';

const initialState = {
  groupId: null,
  devices: [],
  loading: false,
  type: '',
};

const GroupDevicesManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES_SUCCESS:
      return produce(state, (draft) => {
        draft.groupId = payload.groupId;
        draft.devices = payload.devices;
        draft.loading = false;
        draft.type = actionType;
      });
    case GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.devices = payload.devices || state.devices || [];
      });
    case GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.devices = state.devices.filter(x => x.deviceId !== payload.deviceId);
      });
    case GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    default:
      return state;
  }
};

export default GroupDevicesManagement;
