const resetPasswordActions = {
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  RESET_PASSWORD_CLEAN_STATE: 'RESET_PASSWORD_CLEAN_STATE',

  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_ERROR: 'SET_NEW_PASSWORD_ERROR',
  SET_NEW_PASSWORD_CLEAN_STATE: 'SET_NEW_PASSWORD_CLEAN_STATE',
};

const resetPassword = (payload) => ({
  type: resetPasswordActions.RESET_PASSWORD,
  payload,
});
const resetPasswordSuccess = (data) => ({
  type: resetPasswordActions.RESET_PASSWORD_SUCCESS,
  payload: data,
});
const resetPasswordError = ({ message }) => ({
  type: resetPasswordActions.RESET_PASSWORD_ERROR,
  payload: message,
});
const resetPasswordCleanState = () => ({
  type: resetPasswordActions.RESET_PASSWORD_CLEAN_STATE,
});

const setNewPassword = (payload) => ({
  type: resetPasswordActions.SET_NEW_PASSWORD,
  payload,
});
const setNewPasswordSuccess = (data) => ({
  type: resetPasswordActions.SET_NEW_PASSWORD_SUCCESS,
  payload: data,
});
const setNewPasswordError = ({ message }) => ({
  type: resetPasswordActions.SET_NEW_PASSWORD_ERROR,
  payload: message,
});
const setNewPasswordCleanState = () => ({
  type: resetPasswordActions.SET_NEW_PASSWORD_CLEAN_STATE,
});

export {
  resetPasswordActions,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordError,
  resetPasswordCleanState,
  setNewPassword,
  setNewPasswordSuccess,
  setNewPasswordError,
  setNewPasswordCleanState,
};
