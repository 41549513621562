import produce from 'immer';
import { latestDataActions } from './actions';

const initialState = {
  latestData: {},
  loading: false,
  loaded: false,
  loadingError: ''
};

const LatestData = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case latestDataActions.GET_LATEST_DATA:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case latestDataActions.GET_LATEST_DATA_SUCCESS:
      return produce(state, (draft) => {
        for (const [unitId, channels] of Object.entries(payload)) {
          for (const [channelId, channelData] of Object.entries(channels)) {
            if (!draft.latestData[unitId]) {
              draft.latestData[unitId] = {}
            }
            if (!draft.latestData[unitId][channelId]) {
              draft.latestData[unitId][channelId] = {}
            }
            draft.latestData[unitId][channelId] = channelData;
          }
        }
        draft.latestData = payload;
        draft.loading = false;
        draft.loaded = true;
      });
    case latestDataActions.GET_LATEST_DATA_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.loadingError = payload;
      });
    case latestDataActions.GET_LATEST_DATA_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingError = '';
      });
    default:
      return state;
  }
}

export default LatestData;
