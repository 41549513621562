import produce from 'immer';
import { VERSION_ACTION } from './actions';

const initialState = {
  backend: { version: '' },
  loading: false,
};

const Version = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case VERSION_ACTION.GET_BACKEND_VERSION:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case VERSION_ACTION.GET_BACKEND_VERSION_SUCCESS:
      return produce(state, (draft) => {
        draft.backend = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    case VERSION_ACTION.GET_BACKEND_VERSION_FAILED:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default Version;
