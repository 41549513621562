import { all, fork, takeLeading, delay, put, call } from 'redux-saga/effects';
import {
  toastError,
  toastSuccess,
  toastWarning,
} from 'src/common/helpers/Notifications';

import JSZip from 'jszip';
import FileSaver from 'file-saver';

import * as Actions from './actions';
import * as requests from '../../helpers/HTTPRequest';
import ApiConstants from '../../helpers/ApiConstants';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import TextConstants from 'src/common/helpers/TextConstants';

function* fetchGateways() {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GATEWAYS}`;
    const apiRes = yield call(requests.getRequest, url, {});

    yield put(Actions.fetchGatewaysSuccess(apiRes));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.fetchGatewaysError('loading failed'));
  }
}

function* saveGateways({ payload: gatewayData }) {
  const { name, imageData } = gatewayData;
  try {
    // check valid token
    yield checkValidToken();

    const data = { name, imageData };
    // const data = { name: gatewayData.name };
    const url = `${ApiConstants.BASE_URL}${ApiConstants.GATEWAYS}/${gatewayData.gatewayId}`;
    const response = yield call(requests.patchRequest, url, data);
    console.log('response', response);
    yield put(Actions.saveGatewayConfigSuccess());
    toastSuccess(
      TextConstants.Common.EditedSuccessMessage.replace(
        '{item}',
        TextConstants.GatewaySettings.GatewayLabel
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.saveGatewayConfigError('Saving failed'));
  } finally {
    yield delay(500);
    yield put(Actions.saveGatewayConfigCleanState());
  }
}

function downloadCertificate(cert) {
  const zip = new JSZip();
  const fileName = `IoT-thing-certificate_${cert.gatewayId}.zip`;
  zip.file('AmazonRootCA.pem', cert.rootCA1);
  zip.file('certificate.pem.crt', cert.certificatePem);
  zip.file('private.pem.key', cert.privateKey);
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    FileSaver.saveAs(content, fileName);
  });
}

function* addGateway({ payload: gatewayData }) {
  try {
    // check valid token
    yield checkValidToken();
    const url = `${ApiConstants.BASE_URL}${ApiConstants.GATEWAYS}`;
    const res = yield call(requests.postRequest, url, gatewayData);
    downloadCertificate(res.certificate);

    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.GatewaySettings.GatewayLabel
      )
    );
    toastWarning(TextConstants.Common.CertificateDownloadWarning);
    yield put(Actions.addGatewaySuccess());
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.addGatewayError('Adding gateway failed'));
  } finally {
    yield delay(500);
    yield put(Actions.addGatewayCleanState());
  }
}

function* deleteGateway({ payload: gatewayId }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GATEWAYS}/${gatewayId}`;
    yield call(requests.deleteRequest, url);
    yield put(Actions.deleteGatewaySuccess());
    toastSuccess(
      TextConstants.Common.DeletedSuccessMessage.replace(
        '{item}',
        TextConstants.GatewaySettings.GatewayLabel
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(Actions.deleteGatewayError('Deleting gateway failed'));
  } finally {
    yield delay(500);
    yield put(Actions.deleteGatewayCleanState());
  }
}

export function* watchDataView() {
  yield takeLeading(Actions.gatewaysActions.FETCH_GATEWAYS, fetchGateways);
  yield takeLeading(Actions.gatewaysActions.SAVE_GATEWAY_CONFIG, saveGateways);
  yield takeLeading(Actions.gatewaysActions.ADD_GATEWAY, addGateway);
  yield takeLeading(Actions.gatewaysActions.DELETE_GATEWAY, deleteGateway);
}

export default function* rootSaga() {
  yield all([fork(watchDataView)]);
}
