import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import {
  addStoreError,
  addStoreSuccess,
  deleteStoreError,
  deleteStoreSuccess,
  STORES_MANAGEMENT_ACTIONS,
  updateStoreError,
  updateStoreSuccess,
} from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';
import { checkValidToken } from 'src/common/helpers/CheckAuth';

function* getStoresSaga() {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES_MANAGEMENT}`;
    const apiRes = yield call(requests.getRequest, url, {});
    yield put({
      type: STORES_MANAGEMENT_ACTIONS.GET_STORES_SUCCESS,
      payload: apiRes,
    });
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: STORES_MANAGEMENT_ACTIONS.GET_STORES_ERROR,
      payload: 'STORES_MANAGEMENT_ACTIONS.GET_STORES_ERROR',
    });
  }
}

function* addStoreSaga({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES_MANAGEMENT}`;
    const response = yield call(requests.postRequest, url, payload);
    yield put(addStoreSuccess(response));
    toastSuccess(TextConstants.Common.AddedSuccessMessage.replace('{item}', TextConstants.Common.Store));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(addStoreError(e));
  }
}

function* updateStoreSaga({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const { storeId, data } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES_MANAGEMENT}/${storeId}`;
    const response = yield call(requests.putRequest, url, data);
    yield put(updateStoreSuccess(response));
    toastSuccess(TextConstants.Common.EditedSuccessMessage.replace('{item}', storeId));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(updateStoreError(e));
  }
}

function* deleteStoreSaga({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES_MANAGEMENT}/${payload}`;
    yield call(requests.deleteRequest, url);
    yield put(deleteStoreSuccess(payload));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(deleteStoreError(e));
  }
}

export function* StoresManagementSaga() {
  yield takeLatest(STORES_MANAGEMENT_ACTIONS.GET_STORES, getStoresSaga);
  yield takeLatest(STORES_MANAGEMENT_ACTIONS.ADD_STORE, addStoreSaga);
  yield takeLatest(STORES_MANAGEMENT_ACTIONS.UPDATE_STORE, updateStoreSaga);
  yield takeLatest(STORES_MANAGEMENT_ACTIONS.DELETE_STORE, deleteStoreSaga);
}

export default function* rootSaga() {
  yield all([fork(StoresManagementSaga)]);
}
