const TextConstants = {
  Common: {
    User: 'ユーザー',
    Group: 'グループ',
    Logger: 'ロガー',
    ID: 'ID',
    ChannelID: 'チャンネルID',
    AddChannel: 'チャンネルを追加',
    EditChannel: 'チャンネルを編集',
    AddDevice: 'デバイス追加',
    AddingDevice: 'デバイス追加',
    UngroupDevice: 'デバイスをグループ外れ',
    DeleteDevice: 'デバイスの削除',
    DeletingDevice: 'デバイス削除',
    DeleteChannel: 'チャンネル削除',
    EndTime: '終了時間',
    PleaseSelectABC: '{item}を選択してください',
    Current: '現在',
    New: '新しい',
    SelectImage: '画像選択',
    DeleteImage: '画像削除',
    SeeCurrentImage: '現在の画像を見る',
    PleaseSelectDeviceDateRange: 'デバイス ID と日付範囲を選択してください',
    Change: '変更',
    Download: 'ダウンロード',
    ChangeDataFrame: 'データ フレームの変更',
    DataType: 'データタイプ',
    RawData: '生データ',
    HourlyAggregatedData: '時間別集計データ',
    DailyAggregatedData: '日次集計データ',
    FileSizeLimitErrorMessage: 'ファイルサイズは3MB制限があります。',
    FileFormatErrorMessage:
      'ファイル形式はJPG、PNGなどになりますので、ご注意ください。',
    From: 'から',
    To: 'の',
    NoDeviceSelected: 'デバイスが選択されていません',
    DownloadCSV: 'CSVダウンロード',
    PlotData: 'データをプロット',
    Type: 'タイプ',
    Timestamp: 'タイムスタンプ',
    Data: 'データ',
    Search: '検索',
    Logout: 'ログアウト',
    Channels: 'チャンネル',
    NoImage: '画像なし',
    NoGroup: 'グループなし',
    View: 'ビュー',
    DataUnit: 'データユニット',
    NoName: '[名称なし]',
    RegisteredUsers: '登録ユーザー',
    CompanyID: '会社ID',
    CompanyName: '会社名',
    Email: 'メール',
    AddUser: 'ユーザー追加',
    ProvisionallyUsers: '暫定ユーザー',
    FrontEnd: 'FE',
    BackEnd: 'BE',
    loggedOutSuccessfully: '正常にログアウトしました',
    ArsCloudLogin: 'Chino Cloudログイン',
    ValueType: '値のタイプ',
    Actions: 'アクション',
    Add: '追加',
    Cancel: 'キャンセル',
    Delete: '削除',
    DontHavePermissionToEdit: '編集する権限がありません',
    DontHavePermissionToDelete: '削除する権限がありません',
    Edit: '編集',
    EmailFieldWarning: '有効なメールアドレスを入力してください',
    EmptyFieldWarning: '入力が必要です',
    DeleteDeviceChannelWarning:
      'すべてのダッシュボードコントロールが削除されます。',
    OK: 'OK',
    Password: 'パスワード',
    PleaseChoose: '--- 選択 ---',
    RolesConst: {
      SUPER_ADMIN: 'SUPER_ADMIN',
      COMPANY_ADMIN: 'COMPANY_ADMIN',
      DEVICE_ADMIN: 'DEVICE_ADMIN',
      STORE_ADMIN: 'STORE_ADMIN',
      GENERAL_USER: 'GENERAL_USER',
    },
    RolesName: {
      SUPER_ADMIN: 'システム管理者',
      COMPANY_ADMIN: '会社管理者',
      DEVICE_ADMIN: 'デバイス管理者',
      STORE_ADMIN: 'ストア管理者',
      GENERAL_USER: '一般ユーザー',
    },
    Username: 'ユーザー名',
    SavingSettings: '設定を保存します',
    DashboardSettingsHasBeenUpdatedSuccessfully:
      'ダッシュボードの設定が正常に更新されました',
    SavingSuccessfully: '保存しました',
    FailToUpdateDashboardSettings: 'ダッシュボードの設定を更新できませんでした',
    SavingFailed: '保存が失敗しました',
    LoadingSettings: 'ロード設定',
    DashboardSettings: 'ダッシュボード設定',
    Control: 'コントロール',
    LockControl: 'コントロールをロック',
    EditControl: 'コントロールを編集',
    FullScreen: '全画面表示',
    AddControl: 'コントロールを追加',
    NameRequired: '名前が必要です',
    MaxCharacterName: '最大の長さは{length}/30文字です',
    TypeRequired: 'タイプが必要です',
    UnitRequired: 'デバイスが必要です',
    ChannelRequired: 'チャンネルが必要です',
    PriorityRequired: '優先度が必要です',
    FontSizeRequired: '文字サイズが必要です',
    AddNewControl: '新しいコントロールを追加',
    Name: '名前',
    ControlType: 'コントロールタイプ',
    Color: '色',
    Store: 'ストア',
    Device: 'デバイス',
    Channel: 'チャンネル',
    ChannelName: 'チャンネル名',
    Priority: '優先度',
    PriorityHelpText:
      '優先度の高いコントロールが重なる場合、優先度の低いコントロールの上に配置されます',
    Value: '値',
    Time: 'Time',
    NoDataTimeRange: '時間範囲にデータがありません',
    NoDataTimeRangeSelected:
      '選択したチャネルの選択した時間範囲にデータがありません',
    NumberType: 'number',
    StringType: 'string',
    BooleanType: 'boolean',
    NoData: 'データなし',
    TimeStamp: 'タイムスタンプ',
    PointType: 'ポイント値',
    GraphType: 'グラフ',
    LampType: 'ランプ',
    PinType: 'ピン',
    TableType: 'テーブル',
    SetDestination: '行き先を設定する',
    AddedSuccessMessage: '{item} が正常に追加されました',
    EditedSuccessMessage: '{item} が正常に編集されました',
    DeletedSuccessMessage: '{item} が正常に削除されました',
    Success: '成功',
    Warning: '注意',
    Failed: '失敗',
    DeleteConfirmation: '削除の確認',
    DeleteConfirmMessage: 'この {item} を削除してもよろしいですか?',
    Width: '幅',
    Height: '高さ',
    TimeFrame: '時間範囲',
    Minutes: '分',
    Image: '画像',
    Images: '画像',
    Login: 'ログイン',
    LoginPage: 'ログイン画面',
    LastConnected: '最新接続',
    Home: 'ホーム',
    EmailRequest: '登録したメールアドレスを入力',
    PleaseWait: 'お待ちください...',
    Submit: '送信',
    PasswordFieldWarning: 'パスワードが必須です',
    ConfirmPasswordFieldWarning: '確認パスワードが必須です',
    EnterPassword: 'パスワードを入力',
    EnterEmail: 'メールアドレスを入力',
    ExpiredLink: 'リンクの有効期限が切れています',
    RequireEmail: 'メールアドレスが必須です',
    FirstNameRequire: '名前が必須です',
    LastNameRequire: '姓が必須です',
    FirstNameRomajiRequire: '名（ローマ字入力のみ）が必須です',
    LastNameRomajiRequire: '姓（ローマ字入力のみ）が必須です',
    NotMatchPassword: 'パスワードと確認用パスワードが一致しません',
    EnterNewEmail: '新しいメールを入力',
    EnterFirstName: '名を入力',
    EnterLastName: '姓を入力',
    EnterFirstNameRomaji: '名を入力（ローマ字表記）',
    EnterLastNameRomaji: '姓を入力（ローマ字表記）',
    EnterNewPassword: '新しいパスワードを入力',
    EnterConfirmPassword: '確認用パスワードを入力',
    ConfirmPassword: 'パスワードを確認',
    FontSize: '文字サイズ （px）',
    IsShowDateTime: '取得日時',
    Loading: '読み込み中',
    DeviceNotRegistered: '登録されていないデバイス: ',
    ConsentToThePrivacyPolicy: '個人情報保護方針への同意必須。',
    EmailSentSuccessfullyMsg: 'メール送信に成功！',
    AddNewDevice: '新しいデバイスを追加',
    LatestConnection: '最新接続',
    MaxLengthWarningMessage: '最大の長さは{max}文字です',
    Certificate: '証明書',
    CertificateDownloadWarning:
      'ゲートウェイ作成の成功次第、証明書発行します。',
    NotAllowed: '許可しない',
    Error: 'エラー',
    OnAndOffColorShouldBeDifferent: '同色に設定不可能です',
    DateRange: '日付範囲',
    TimeRange: '時間範囲',
    EnterAtLeastOneCharacter: '少なくとも1文字以上入力してください。',
    SetFontSizeTo: '文字サイズは',
    OrMore: '以上で設定してください',
    OrLess: '以下で設定してください',
    SetThePriority: '優先度は',
    BetweenTheRange: 'の範囲で設定してください',
    EnterThePriorityAsAnInteger: '優先度は整数で入力してください。',
    EnterTheFontSizeAsAnInteger: '文字サイズは整数で入力してください。',
    TheClosestValuesAre: '最も近い値は',
    And: 'と',
    Desu: 'です',
    PasswordMustBeAtLeast8Characters:
      'パスワードは８文字以上である必要があります',
    ThisDeviceDoesntHaveAnyChannel: '選択されたデバイスには、',
    ConfiguredAsNumberType: 'タイプとして設定されたチャンネルがありません',
    SessionExpired:
      'セッションの有効期限が切れました。再度ログインしてください。',
  },
  UsersManagement: {
    Title: 'ユーザー管理',
    AddUser: 'ユーザー追加',
    CancelChangePassword: 'パスワード変更をキャンセル',
    ChangePassword: 'パスワード変更',
    CompanyName: '会社名',
    DepartmentName: '部門名',
    Email: 'メール',
    FirstName: '名',
    LastName: '姓',
    FirstNameRomaji: '名（ローマ字入力のみ）',
    LastNameRomaji: '姓（ローマ字入力のみ）',
    GeneralUser: '一般ユーザー',
    Roles: '役割',
    StoreName: 'ストア名',
    UsersManagement: 'ユーザー管理',
    ForgotPassword: 'パスワードを忘れた方',
    ResetPassword: 'パスワードリセット',
    Register: '登録',
    Login: 'ログイン',
    SuperAdmin: 'スーパー管理者',
    CompanyAdmin: '会社の管理者',
    StoreAdmin: 'ストア管理者',
    PasswordWarningMessage:
      'パスワードは長さが8~99文字で、数字、特殊文字、英大文字、英小文字のそれぞれを最低1文字ずつ含む必要があります',
    RomajiWarningMessage: '全てローマ字表記にする必要があります。',
    CompanyNameMessage: '半角英数および「. - &」記号で入力してください。',
    Reset: 'リセット',
    // CompanyAdminOnly: '会社の管理者のみ',
    // CompanyAndAdmin: '会社と管理者',
    CompanyType: { Merchandise: 'MZ商品', General: '一般' },
    StoreAdminOnly: 'ストア管理者のみ',
    StoreAndAdmin: '新しいストアと管理者',
    DisplayName: '表示名',
    DeleteUser: 'ユーザーを削除します',
    DeleteUserConfirmationMessage: 'こちらのユーザーを削除しますか？',
    DeleteCompanyAdminConfirmationMessage: '選択の会社を削除しますか？会社情報、店舗、ユーザーなどが永久に削除されますので、ご注意ください。',
    userNo: 'ユーザNo',
  },
  StoresManagement: {
    Title: 'ストア管理',
    Stores: 'ストア',
    AddStore: 'ストア追加',
    EditStore: 'ストア編集',
    DeleteStore: 'ストア削除',
    StoreName: 'ストア名',
  },
  GroupsManagement: {
    Title: 'グループ管理',
    Groups: 'グループ',
    AddGroup: 'グループ追加',
    EditGroup: 'グループ編集',
    DeleteGroup: 'グループ削除',
    GroupName: 'グループ名',
    StoreList: 'ストア',
    GroupDevices: {
      Title: 'グループの詳細',
      DeviceName: 'デバイス名',
    },
    GroupStores: {
      Title: 'グループの詳細',
      DeviceName: 'デバイス名',
      AccessLevel: '許可レベル',
    },
  },
  LoggersManagement: {
    Title: 'ロガー設定',
    Loggers: 'ロガー',
    AddLogger: 'ロガーを追加する',
    LoggerId: 'ロガーID',
    LoggerName: 'ロガー名',
    LoggerGroup: 'ロガーグループ',
  },
  LastData: {
    Title: '最新データ',
  },
  Dashboard: {
    Title: 'ダッシュボード',
    ControlAdded: 'コントロールが正常に追加されました。',
    ControlUpdated: 'コントロールが正常に更新されました。',
    ControlDeleted: 'コントロールが正常に削除されました。',
    UpdatePositionOrSizeFailedErrMsg:
      'コントロールの位置またはサイズを更新できませんでした',
    SetPinPositionFailedErrMsg: 'ピン位置の設定に失敗しました',
  },
  DataView: {
    Title: 'データビュー',
    NoDeviceSelected: 'デバイスを選択してください',
    NoChannelSelected: 'チャンネルを選択してください',
    RawDataDateRangeError: '日時範囲は30分以上6時間未満に設定してください',
    HourlyDataDateRangeError: '日付範囲は1日以上1年未満に設定してください',
    DailyDataDateRangeError: '日付範囲は1日以上5年未満に設定してください',
    EndpointTimeOutError:
      '取得するデータが多すぎます。データ取得範囲を狭めるか、チャンネル数を減らしてください。',
    Average: '平均値',
    Minimum: '最小値',
    Maximum: '最大値',
    Date: '日付',
  },
  DeviceSettings: {
    Title: 'デバイス設定',
    EditDevice: 'デバイスを編集：',
  },
  GatewaySettings: {
    GatewayLabel: 'ゲートウェイ',
    Title: 'ゲートウェイ設定',
    GatewayID: 'ゲートウェイID',
    GatewayName: 'ゲートウェイ名',
    AddNewGateway: '新しいゲートウェイを追加：',
    DeleteGateway: 'ゲートウェイを削除：',
    EditGateway: 'ゲートウェイを編集：',
  },
  ChannelSettings: {
    Title: 'チャンネル設定',
  },
  Permissions: {
    READ_WRITE: 'Read Write',
    READ_ONLY: 'Read Only',
  },
  Register: {
    TermsOfUse: 'サービス利用規約',
    PrivacyPolicy: 'プライバシーポリシー',
    Agree: 'に同意します。',
    AcceptAllTermsOfUse: '利用規約に同意します。',
  },
};

export default TextConstants;
