const currentEnv = process.env.REACT_APP_ENV || 'dev';
const version = 'v1';
const baseUrl = {
  dev: 'https://6h3vui57x7.execute-api.ap-northeast-1.amazonaws.com/api',
  mzcloud: 'https://b7aidjpfei.execute-api.ap-northeast-1.amazonaws.com/api',
  new_chino_cloud_prod: 'https://api.chino.cloud',
  new_chino_cloud_stg: 'https://zbwlgj8rrh.execute-api.ap-northeast-1.amazonaws.com/api',
  new_chino_cloud_dev: 'https://lvy2sf2c14.execute-api.ap-northeast-1.amazonaws.com/api',
};
const ApiConstants = {
  BASE_URL: `${baseUrl[currentEnv]}/${version}/`,
  LOGIN_URL: 'login',
  RESET_PASSWORD: 'reset_password',
  SET_NEW_PASSWORD: 'set_new_password',
  GATEWAYS: 'gateways',
  UNITS: 'devices',
  REALTIME_DATA: 'data',
  DASHBOARD: 'dashboard',
  DATA_VIEW: 'data/aggregated',
  LAST_DATA: 'data/latest',
  USERS_MANAGEMENT: 'users',
  GROUPS_MANAGEMENT: 'device_groups',
  LOGGERS_MANAGEMENT: 'loggers',
  ACTIVE_USERS: 'active_users',
  COMPANIES_MANAGEMENT: 'companies',
  STORES_MANAGEMENT: 'stores',
  DEPARTMENTS_MANAGEMENT: 'departments',
  LAST_SEQUENCE: 'last_sequence',
  GET_NEXT_SEQUENCE_VALUE: 'last_sequence/{:tableName}/next_sequence_value',
  UPDATE_USER_STATUS: 'update/user/status',
  RESEND_INVITATION_MAIL: 'resend/invitation',
  GET_DEVICE_CHANNELS: 'devices/{:deviceId}/channels',
  BACKEND_VERSION: 'version',
};

export default ApiConstants;
