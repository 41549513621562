const loginActions = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_CLEAN_STATE: 'LOGIN_CLEAN_STATE',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  LOGOUT_CLEAN_STATE: 'LOGOUT_CLEAN_STATE',
  TOKEN_EXPIRED_SUCCESS: 'TOKEN_EXPIRED_SUCCESS',
};

const performLogin = (username, password) => ({
  type: loginActions.LOGIN,
  payload: { username, password },
});

const performLoginSuccess = (data) => ({
  type: loginActions.LOGIN_SUCCESS,
  payload: data,
});

const performLoginError = ({ message }) => ({
  type: loginActions.LOGIN_ERROR,
  payload: message,
});

const performLoginCleanState = () => ({
  type: loginActions.LOGIN_CLEAN_STATE,
});

const performLogout = (isTokenExpired = false) => ({
  type: loginActions.LOGOUT,
  payload: { isTokenExpired },
});

const performLogoutSuccess = (data) => ({
  type: loginActions.LOGOUT_SUCCESS,
  payload: data,
});

const performLogoutError = ({ message }) => ({
  type: loginActions.LOGOUT_ERROR,
  payload: message,
});

const performLogoutCleanState = () => ({
  type: loginActions.LOGOUT_CLEAN_STATE,
});

const performTokenExpiredSuccess = (message) => ({
  type: loginActions.TOKEN_EXPIRED_SUCCESS,
  payload: message,
});

export {
  loginActions,
  performLogin,
  performLoginSuccess,
  performLoginError,
  performLoginCleanState,
  performLogout,
  performLogoutSuccess,
  performLogoutError,
  performLogoutCleanState,
  performTokenExpiredSuccess,
};
