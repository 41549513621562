import produce from 'immer';
import { loginActions } from './actions';

const initialState = {
  login: {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: '',
  },
  logout: {
    loading: false,
    loaded: false,
    success: false,
    successMessage: '',
    errorMessage: '',
  },
};

const Login = (state = initialState, action) => {
  switch (action.type) {
    case loginActions.LOGIN:
      return produce(state, (draft) => {
        draft.login = {
          loading: true,
          loaded: false,
          success: false,
          errorMessage: '',
        };
      });
    case loginActions.LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.login = {
          loading: false,
          loaded: true,
          success: true,
        };
      });
    case loginActions.LOGIN_ERROR:
      return produce(state, (draft) => {
        draft.login = {
          loading: false,
          loaded: true,
          success: false,
          errorMessage: action.payload,
        };
      });
    case loginActions.LOGIN_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.login = {
          ...draft.login,
          loading: false,
          loaded: false,
          success: false,
        };
      });
    case loginActions.LOGOUT:
      return produce(state, (draft) => {
        draft.logout = {
          loading: true,
          loaded: false,
          success: false,
          successMessage: '',
        };
      });
    case loginActions.LOGOUT_SUCCESS:
      return produce(state, (draft) => {
        draft.logout = {
          loading: false,
          loaded: true,
          success: true,
          successMessage: action.payload,
        };
      });
    case loginActions.LOGOUT_ERROR:
      return produce(state, (draft) => {
        draft.logout = {
          loading: false,
          loaded: true,
          success: false,
        };
      });
    case loginActions.LOGOUT_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.logout = {
          loading: false,
          loaded: false,
          success: false,
          successMessage: '',
          errorMessage: '',
        };
      });
    case loginActions.TOKEN_EXPIRED_SUCCESS:
      return produce(state, (draft) => {
        draft.logout = {
          loading: false,
          loaded: false,
          success: false,
          errorMessage: action.payload,
        };
      });
    default:
      return state;
  }
};

export default Login;
