export const GROUP_STORES_ACTIONS = {
  GET_GROUP_STORES: 'GET_GROUP_STORES',
  GET_GROUP_STORES_SUCCESS: 'GET_GROUP_STORES_SUCCESS',
  GET_GROUP_STORES_ERROR: 'GET_GROUP_STORES_ERROR',
  GET_GROUP_STORES_CLEAN_STATE: 'GET_GROUP_STORES_CLEAN_STATE',
  ADD_GROUP_STORES: 'ADD_GROUP_STORES',
  ADD_GROUP_STORES_SUCCESS: 'ADD_GROUP_STORES_SUCCESS',
  ADD_GROUP_STORES_ERROR: 'ADD_GROUP_STORES_ERROR',
  ADD_GROUP_STORES_CLEAN_STATE: 'ADD_GROUP_STORES_CLEAN_STATE',
  EDIT_GROUP_STORE_PERMISSION: 'EDIT_GROUP_STORE_PERMISSION',
  EDIT_GROUP_STORE_PERMISSION_SUCCESS: 'EDIT_GROUP_STORE_PERMISSION_SUCCESS',
  EDIT_GROUP_STORE_PERMISSION_ERROR: 'EDIT_GROUP_STORE_PERMISSION_ERROR',
  EDIT_GROUP_STORE_PERMISSION_CLEAN_STATE: 'EDIT_GROUP_STORE_PERMISSION_CLEAN_STATE',
  UNLINK_GROUP_STORE: 'UNLINK_GROUP_STORE',
  UNLINK_GROUP_STORE_SUCCESS: 'UNLINK_GROUP_STORE_SUCCESS',
  UNLINK_GROUP_STORE_ERROR: 'UNLINK_GROUP_STORE_ERROR',
  UNLINK_GROUP_STORE_CLEAN_STATE: 'UNLINK_GROUP_STORE_CLEAN_STATE',
};
export const getGroupStores = (params) => ({
  type: GROUP_STORES_ACTIONS.GET_GROUP_STORES,
  payload: params,
});
export const getGroupStoresSuccess = (data) => ({
  type: GROUP_STORES_ACTIONS.GET_GROUP_STORES_SUCCESS,
  payload: data,
});
export const getGroupStoresError = ({ message }) => ({
  type: GROUP_STORES_ACTIONS.GET_GROUP_STORES_ERROR,
  payload: message,
});
export const getGroupStoresCleanState = () => ({
  type: GROUP_STORES_ACTIONS.GET_GROUP_STORES_CLEAN_STATE,
});
// =========================================================================
export const addGroupStores = (params) => ({
  type: GROUP_STORES_ACTIONS.ADD_GROUP_STORES,
  payload: params,
});
export const addGroupStoresSuccess = (data) => ({
  type: GROUP_STORES_ACTIONS.ADD_GROUP_STORES_SUCCESS,
  payload: data,
});
export const addGroupStoresError = ({ message }) => ({
  type: GROUP_STORES_ACTIONS.ADD_GROUP_STORES_ERROR,
  payload: message,
});
export const addGroupStoresCleanState = () => ({
  type: GROUP_STORES_ACTIONS.ADD_GROUP_STORES_CLEAN_STATE,
});
// =========================================================================
export const editGroupStorePermission = (params) => ({
  type: GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION,
  payload: params,
});
export const editGroupStorePermissionSuccess = (data) => ({
  type: GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION_SUCCESS,
  payload: data,
});
export const editGroupStorePermissionError = ({ message }) => ({
  type: GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION_ERROR,
  payload: message,
});
export const editGroupStorePermissionCleanState = () => ({
  type: GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION_CLEAN_STATE,
});
// =========================================================================
export const unlinkGroupStore = (params) => ({
  type: GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE,
  payload: params,
});
export const unlinkGroupStoreSuccess = (data) => ({
  type: GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE_SUCCESS,
  payload: data,
});
export const unlinkGroupStoreError = ({ message }) => ({
  type: GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE_ERROR,
  payload: message,
});
export const unlinkGroupStoreCleanState = () => ({
  type: GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE_CLEAN_STATE,
});
// =========================================================================
