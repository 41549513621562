import { all, fork, put, takeLatest, call, delay } from 'redux-saga/effects';
import {
  deleteLoggerCleanState,
  deleteLoggerError,
  deleteLoggerSuccess,
  getLoggersError,
  getLoggersSuccess,
  LOGGERS_MANAGEMENT_ACTIONS,
} from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';

function* getLoggers() {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.LOGGERS_MANAGEMENT}`;
    const groups = yield call(requests.getRequest, url, {});

    yield put(getLoggersSuccess(groups));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(getLoggersError(LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS_ERROR));
  }
}

function* addLogger({ payload: userData }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.LOGGERS_MANAGEMENT}`;
    const response = yield call(requests.postRequest, url, userData);
    yield put({
      type: LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_SUCCESS,
      payload: response,
    });
    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Logger
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_ERROR,
    });
  }
}

function* updateLogger({ payload: userData }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.LOGGERS_MANAGEMENT}/${userData.username}`;
    const apiRes = yield call(requests.patchRequest, url, userData);
    yield put({
      type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_SUCCESS,
      payload: apiRes,
    });
    toastSuccess('User updated successfully');
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_ERROR,
      payload: 'LOGGERS_MANAGEMENT_ACTIONS.SAVE_DATA_ERROR',
    });
  } finally {
    yield delay(500);
    yield put({ type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_CLEAN_STATE });
  }
}

function* deleteLogger({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.LOGGERS_MANAGEMENT}/${payload}`;
    yield call(requests.deleteRequest, url);
    yield put(deleteLoggerSuccess(payload));
    toastSuccess('User deleted successfully');
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(deleteLoggerError(e));
  } finally {
    yield delay(500);
    yield put(deleteLoggerCleanState());
  }
}

export function* LoggersManagementSaga() {
  yield takeLatest(LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS, getLoggers);
  yield takeLatest(LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER, addLogger);
  yield takeLatest(LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER, updateLogger);
  yield takeLatest(LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER, deleteLogger);
}

export default function* rootSaga() {
  yield all([fork(LoggersManagementSaga)]);
}
