import produce from 'immer';
import { GROUPS_MANAGEMENT_ACTIONS } from './actions';

const initialState = {
  groups: [],
  loading: false,
  type: '',
};

const GroupsManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS:
    case GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP:
    case GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP:
    case GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS_SUCCESS:
      return produce(state, (draft) => {
        draft.groups = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    case GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_SUCCESS:
      return produce(state, (draft) => {
        draft.groups = [payload, ...state.groups];
        draft.loading = false;
        draft.type = actionType;
      });
    case GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP_SUCCESS:
      return produce(state, (draft) => {
        draft.groups = state.groups.map((x) =>
          x.groupId === payload.groupId ? ({ ...x, ...payload.data }) : x
        );
        draft.loading = false;
        draft.type = actionType;
      });
    case GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP_SUCCESS:
      return produce(state, (draft) => {
        draft.groups = state.groups.filter(
          (x) => x.groupId !== payload
        );
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS_ERROR:
    case GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_ERROR:
    case GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP_ERROR:
    case GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP_ERROR:
    case GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP_CLEAN_STATE:
    case GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_CLEAN_STATE:
    case GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default GroupsManagement;
