import produce from 'immer';
import { registeredActions } from './actions';

const initialState = {
  activateUser: {
    loading: false,
    success: false,
  },
};

const ActivateUser = (state = initialState, action) => {
  switch (action.type) {
    case registeredActions.ACTIVATE_USER:
      return produce(state, (draft) => {
        draft.activateUser = {
          loading: true,
          success: false,
        };
      });
    case registeredActions.ACTIVATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.activateUser = {
          loading: false,
          success: true,
        };
      });
    case registeredActions.ACTIVATE_USER_ERROR:
      return produce(state, (draft) => {
        draft.activateUser = {
          loading: false,
          success: false,
        };
      });
    case registeredActions.ACTIVATE_USER_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.activateUser = {
          loading: false,
          success: false,
        };
      });
    default:
      return state;
  }
};

export default ActivateUser;
