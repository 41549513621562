import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './style/style.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { parseJwt } from './common/helpers';
import Utils from './common/helpers/Utils';
import { loginActions } from 'src/views/public/login/actions';
import { getUserToken } from 'src/common/helpers/StorageUtils';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./layout/TheLayout'));

// Login Page
const LoginPage = React.lazy(() => import('./views/public/login'));
// Forgot Password Page
const ForgotPassword = React.lazy(() =>
  import('./views/public/forgot-password')
);
// Reset Password Page
const ResetPassword = React.lazy(() => import('./views/public/reset-password'));
// Register Page
const Register = React.lazy(() => import('./views/public/register'));

// Check whether logged in or not, if loggein than redirected to dashboard
const AuthRoute = ({ component: Component, auth, ...rest }) => {
  const defaultPagePath = auth
    ? Utils.getDefaultPagePath(parseJwt(auth)['custom:role'])
    : '/login';
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Redirect to={defaultPagePath} /> : <Component {...props} />
      }
    />
  );
};

const App = () => {
  const { idToken } = useSelector((state) => state.User);

  useEffect(() => {
    const channel = new BroadcastChannel('AUTH_CHANNEL');
    channel.addEventListener('message', (e) => {
      const messageData = e.data;
      if (messageData) {
        const actionType = messageData.actionType;
        switch (actionType) {
          case loginActions.LOGIN_SUCCESS:
            const token = getUserToken();
            if (!token) window.location.reload();

          case loginActions.LOGOUT_SUCCESS:
          case loginActions.TOKEN_EXPIRED_SUCCESS:
            window.location.reload();
          default:
            break;
        }
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <AuthRoute
            exact
            path="/login"
            name="Login Page"
            auth={idToken || ''}
            component={LoginPage}
          />
          <AuthRoute
            exact
            path="/forgot-password"
            name="Forgot Password"
            auth={idToken || ''}
            component={ForgotPassword}
          />
          <AuthRoute
            exact
            path="/reset_password"
            name="Reset Password"
            auth={idToken || ''}
            component={ResetPassword}
          />
          <AuthRoute
            exact
            path="/register"
            name="Register"
            auth={idToken || ''}
            component={Register}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
        <NotificationContainer />
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;

AuthRoute.propTypes = {
  auth: PropTypes.string,
  component: PropTypes.elementType,
};
