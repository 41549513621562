export const GROUPS_MANAGEMENT_ACTIONS = {
  GET_GROUPS: 'GET_GROUPS',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_ERROR: 'GET_GROUPS_ERROR',
  GET_GROUPS_CLEAN_STATE: 'GET_GROUPS_CLEAN_STATE',
  ADD_GROUP: 'ADD_GROUP',
  ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
  ADD_GROUP_ERROR: 'ADD_GROUP_ERROR',
  ADD_GROUP_CLEAN_STATE: 'ADD_GROUP_CLEAN_STATE',
  UPDATE_GROUP: 'UPDATE_GROUP',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_ERROR: 'UPDATE_GROUP_ERROR',
  UPDATE_GROUP_CLEAN_STATE: 'UPDATE_GROUP_CLEAN_STATE',
  DELETE_GROUP: 'DELETE_GROUP',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_ERROR: 'DELETE_GROUP_ERROR',
  DELETE_GROUP_CLEAN_STATE: 'DELETE_GROUP_CLEAN_STATE',
};
// =========================================================================
export const getGroups = () => ({
  type: GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS,
});
export const getGroupsSuccess = (data) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS_SUCCESS,
  payload: data,
});
export const getGroupsError = ({ message }) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS_ERROR,
  payload: message,
});
export const getGroupsCleanState = () => ({
  type: GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS_CLEAN_STATE,
});
// =========================================================================
export const addGroup = (data) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP,
  payload: data,
});
export const addGroupSuccess = () => ({
  type: GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_SUCCESS,
});
export const addGroupError = ({ message }) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_ERROR,
  payload: message,
});
export const addGroupCleanState = () => ({
  type: GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_CLEAN_STATE,
});
// =========================================================================
export const updateGroup = (data) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP,
  payload: data,
});
export const updateGroupSuccess = (data) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP_SUCCESS,
  payload: data,
});
export const updateGroupError = ({ message }) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP_ERROR,
  payload: message,
});
export const updateGroupCleanState = () => ({
  type: GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP_CLEAN_STATE,
});
// =========================================================================
export const deleteGroup = (data) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP,
  payload: data,
});
export const deleteGroupSuccess = (data) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP_SUCCESS,
  payload: data,
});
export const deleteGroupError = ({ message }) => ({
  type: GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP_ERROR,
  payload: message,
});
export const deleteGroupCleanState = () => ({
  type: GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP_CLEAN_STATE,
});
// =========================================================================