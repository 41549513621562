const dashboardActions = {
  GET_DASHBOARD_SETTINGS: 'GET_DASHBOARD_SETTINGS',
  GET_DASHBOARD_SETTINGS_SUCCESS: 'GET_DASHBOARD_SETTINGS_SUCCESS',
  GET_DASHBOARD_SETTINGS_ERROR: 'GET_DASHBOARD_SETTINGS_ERROR',
  GET_DASHBOARD_SETTINGS_CLEAN_STATE: 'GET_DASHBOARD_SETTINGS_CLEAN_STATE',

  SAVE_DASHBOARD_SETTINGS: 'SAVE_DASHBOARD_SETTINGS',
  SAVE_DASHBOARD_SETTINGS_SUCCESS: 'SAVE_DASHBOARD_SETTINGS_SUCCESS',
  SAVE_DASHBOARD_SETTINGS_ERROR: 'SAVE_DASHBOARD_SETTINGS_ERROR',
  SAVE_DASHBOARD_SETTINGS_CLEAN_STATE: 'SAVE_DASHBOARD_SETTINGS_CLEAN_STATE',

  ADD_CONTROL: 'ADD_CONTROL',
  ADD_CONTROL_SUCCESS: 'ADD_CONTROL_SUCCESS',
  ADD_CONTROL_ERROR: 'ADD_CONTROL_ERROR',
  ADD_CONTROL_CLEAN_STATE: 'ADD_CONTROL_CLEAN_STATE',

  EDIT_CONTROL: 'EDIT_CONTROL',
  EDIT_CONTROL_SUCCESS: 'EDIT_CONTROL_SUCCESS',
  EDIT_CONTROL_ERROR: 'EDIT_CONTROL_ERROR',
  EDIT_CONTROL_CLEAN_STATE: 'EDIT_CONTROL_CLEAN_STATE',

  EDIT_CONTROL_POSITION_AND_SIZE: 'EDIT_CONTROL_POSITION_AND_SIZE',
  EDIT_CONTROL_POSITION_AND_SIZE_SUCCESS:
    'EDIT_CONTROL_POSITION_AND_SIZE_SUCCESS',
  EDIT_CONTROL_POSITION_AND_SIZE_ERROR: 'EDIT_CONTROL_POSITION_AND_SIZE_ERROR',
  EDIT_CONTROL_POSITION_AND_SIZE_CLEAN_STATE:
    'EDIT_CONTROL_POSITION_AND_SIZE_CLEAN_STATE',

  UPDATE_DRAGGED_CONTROL: 'UPDATE_DRAGGED_CONTROL',

  DELETE_CONTROL: 'DELETE_CONTROL',
  DELETE_CONTROL_SUCCESS: 'DELETE_CONTROL_SUCCESS',
  DELETE_CONTROL_ERROR: 'DELETE_CONTROL_ERROR',
  DELETE_CONTROL_CLEAN_STATE: 'DELETE_CONTROL_CLEAN_STATE',

  SET_PIN_DESTINATION: 'SET_PIN_DESTINATION',
  SET_PIN_DESTINATION_SUCCESS: 'SET_PIN_DESTINATION_SUCCESS',
  SET_PIN_DESTINATION_ERROR: 'SET_PIN_DESTINATIONL_ERROR',
  SET_PIN_DESTINATION_CLEAN_STATE: 'SET_PIN_DESTINATION_CLEAN_STATE',
  
  UDPATE_FONT_SIZE: 'UDPATE_FONT_SIZE',
};

const getDashboardSettings = () => ({
  type: dashboardActions.GET_DASHBOARD_SETTINGS,
});

const getDashboardSettingsSuccess = (data) => ({
  type: dashboardActions.GET_DASHBOARD_SETTINGS_SUCCESS,
  payload: data,
});

const getDashboardSettingsError = ({ message }) => ({
  type: dashboardActions.GET_DASHBOARD_SETTINGS_ERROR,
  payload: message,
});

const getDashboardSettingsCleanState = () => ({
  type: dashboardActions.GET_DASHBOARD_SETTINGS_CLEAN_STATE,
});

const saveDashboardSettings = (data) => ({
  type: dashboardActions.SAVE_DASHBOARD_SETTINGS,
  payload: data,
});
const saveDashboardSettingsSuccess = () => ({
  type: dashboardActions.SAVE_DASHBOARD_SETTINGS_SUCCESS,
});
const saveDashboardSettingsError = ({ message }) => ({
  type: dashboardActions.SAVE_DASHBOARD_SETTINGS_ERROR,
  payload: message,
});
const saveDashboardSettingsCleanState = () => ({
  type: dashboardActions.SAVE_DASHBOARD_SETTINGS_CLEAN_STATE,
});

const addControl = (data) => ({
  type: dashboardActions.ADD_CONTROL,
  payload: data,
});
const addControlSuccess = (data) => ({
  type: dashboardActions.ADD_CONTROL_SUCCESS,
  payload: data,
});
const addControlError = ({ message }) => ({
  type: dashboardActions.ADD_CONTROL_ERROR,
  payload: message,
});
const addControlCleanState = () => ({
  type: dashboardActions.ADD_CONTROL_CLEAN_STATE,
});

const editControl = (data) => ({
  type: dashboardActions.EDIT_CONTROL,
  payload: data,
});
const editControlSuccess = (data) => ({
  type: dashboardActions.EDIT_CONTROL_SUCCESS,
  payload: data,
});
const editControlError = () => ({
  type: dashboardActions.EDIT_CONTROL_ERROR,
});
const editControlCleanState = () => ({
  type: dashboardActions.EDIT_CONTROL_CLEAN_STATE,
});

const deleteControl = (data) => ({
  type: dashboardActions.DELETE_CONTROL,
  payload: data,
});
const deleteControlSuccess = (data) => ({
  type: dashboardActions.DELETE_CONTROL_SUCCESS,
  payload: data,
});
const deleteControlError = ({ message }) => ({
  type: dashboardActions.DELETE_CONTROL_ERROR,
  payload: message,
});
const deleteControlCleanState = () => ({
  type: dashboardActions.DELETE_CONTROL_CLEAN_STATE,
});

const updateDraggedControl = (controls) => ({
  type: dashboardActions.UPDATE_DRAGGED_CONTROL,
  payload: controls,
});

const editControlPositionAndSize = (data) => ({
  type: dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE,
  payload: data,
});
const editControlPositionAndSizeSuccess = (data) => ({
  type: dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE_SUCCESS,
  payload: data,
});
const editControlPositionAndSizeError = () => ({
  type: dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE_ERROR,
});
const editControlPositionAndSizeCleanState = () => ({
  type: dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE_CLEAN_STATE,
});

const setPinDestination = (controlId, destinationPoint) => ({
  type: dashboardActions.SET_PIN_DESTINATION,
  payload: { controlId, destinationPoint },
});
const setPinDestinationSuccess = (data) => ({
  type: dashboardActions.SET_PIN_DESTINATION_SUCCESS,
  payload: data,
});
const setPinDestinationError = () => ({
  type: dashboardActions.SET_PIN_DESTINATION_ERROR,
});
const setPinDestinationCleanState = () => ({
  type: dashboardActions.SET_PIN_DESTINATION_CLEAN_STATE,
});
const updateFontSize = (data) => ({
  type: dashboardActions.UDPATE_FONT_SIZE,
  payload: data,
});

export {
  dashboardActions,
  getDashboardSettings,
  getDashboardSettingsSuccess,
  getDashboardSettingsError,
  getDashboardSettingsCleanState,
  saveDashboardSettings,
  saveDashboardSettingsSuccess,
  saveDashboardSettingsError,
  saveDashboardSettingsCleanState,
  addControl,
  addControlSuccess,
  addControlError,
  addControlCleanState,
  editControl,
  editControlSuccess,
  editControlError,
  editControlCleanState,
  deleteControl,
  deleteControlSuccess,
  deleteControlError,
  deleteControlCleanState,
  editControlPositionAndSize,
  editControlPositionAndSizeSuccess,
  editControlPositionAndSizeError,
  editControlPositionAndSizeCleanState,
  updateDraggedControl,
  setPinDestination,
  setPinDestinationSuccess,
  setPinDestinationError,
  setPinDestinationCleanState,
  updateFontSize,
};
