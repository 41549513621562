import { all, fork, takeLeading, put, call, delay } from 'redux-saga/effects';
import {
  resetPasswordActions,
  resetPasswordSuccess,
  resetPasswordError,
  resetPasswordCleanState,
  setNewPasswordSuccess,
  setNewPasswordError,
  setNewPasswordCleanState,
} from './actions';
import * as requests from '../../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../../common/helpers/ApiConstants';

function* resetPassword({ payload }) {
  try {
    const requestBody = { ...payload };
    const url = `${ApiConstants.BASE_URL}${ApiConstants.RESET_PASSWORD}`;
    const apiRes = yield call(requests.postRequest, url, requestBody, false);
    if (apiRes) {
      yield put(resetPasswordSuccess(apiRes?.message || ''));
    } else {
      yield put(resetPasswordError({ message: 'Oops! something went wrong!' }));
    }
  } catch (error) {
    console.log(error);
    yield put(resetPasswordError({ message: error?.message || '' }));
  } finally {
    yield delay(2500);
    yield put(resetPasswordCleanState());
  }
}

function* setNewPassword({ payload }) {
  try {
    const requestBody = { ...payload };
    const url = `${ApiConstants.BASE_URL}${ApiConstants.SET_NEW_PASSWORD}`;
    const apiRes = yield call(requests.postRequest, url, requestBody, false);
    if (apiRes) {
      yield put(setNewPasswordSuccess(apiRes?.message || ''));
    } else {
      yield put(
        setNewPasswordError({ message: 'Oops! something went wrong!' })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(setNewPasswordError({ message: error?.message || '' }));
  } finally {
    yield delay(5000);
    yield put(setNewPasswordCleanState());
  }
}

export function* watchForgotPassword() {
  yield takeLeading(resetPasswordActions.RESET_PASSWORD, resetPassword);
  yield takeLeading(resetPasswordActions.SET_NEW_PASSWORD, setNewPassword);
}

export default function* rootSaga() {
  yield all([fork(watchForgotPassword)]);
}
