export const getUserToken = () => {
  return localStorage.getItem('UserIdToken');
};
export const setUserToken = (token) => {
  localStorage.setItem('UserIdToken', `${token}`);
};
export const unsetUserToken = () => {
  localStorage.removeItem('UserIdToken');
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo')) || {};
};
export const setUserInfo = (userInfo) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};
export const unsetUserInfo = () => {
  localStorage.removeItem('userInfo');
};
