import produce from 'immer';
import { aggregatedDataActions } from './actions';

const initialState = {
  dataViewData: [],
  loading: false,
  loaded: false,
  success: false,
  error: false,
  errorMessage: '',
  actionType: '',
};

const DataView = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case aggregatedDataActions.FETCH_DATA_VIEW:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.loaded = false;
        draft.success = false;
        draft.error = false;
        draft.errorMessage = '';
        draft.actionType = actionType;
      });
    case aggregatedDataActions.FETCH_DATA_VIEW_SUCCESS:
      return produce(state, (draft) => {
        draft.dataViewData = payload.data;
        draft.loading = false;
        draft.loaded = true;
        draft.success = true;
        draft.error = false;
        draft.actionType = actionType;
      });
    case aggregatedDataActions.FETCH_DATA_VIEW_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.success = false;
        draft.error = true;
        draft.errorMessage = payload;
        draft.actionType = actionType;
      });
    case aggregatedDataActions.FETCH_DATA_VIEW_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.success = false;
        draft.error = false;
        draft.errorMessage = '';
        draft.actionType = actionType;
      });
    default:
      return state;
  }
};

export default DataView;
