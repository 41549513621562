import produce from 'immer';
import { resetPasswordActions } from './actions';

const initialState = {
  loading: false,
  loaded: false,
  successMessage: '',
  errorMessage: '',
  setNewPassword: {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: '',
  },
};

const ResetPassword = (state = initialState, action) => {
  switch (action.type) {
    case resetPasswordActions.RESET_PASSWORD:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.loaded = false;
        draft.successMessage = '';
        draft.errorMessage = '';
      });
    case resetPasswordActions.RESET_PASSWORD_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.successMessage = action.payload;
      });
    case resetPasswordActions.RESET_PASSWORD_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.successMessage = '';
        draft.errorMessage = action.payload;
      });
    case resetPasswordActions.RESET_PASSWORD_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = false;
        draft.successMessage = '';
        draft.errorMessage = '';
      });
    case resetPasswordActions.SET_NEW_PASSWORD:
      return produce(state, (draft) => {
        draft.setNewPassword = {
          loading: true,
          loaded: false,
          success: false,
          successMessage: '',
          errorMessage: '',
        };
      });
    case resetPasswordActions.SET_NEW_PASSWORD_SUCCESS:
      return produce(state, (draft) => {
        draft.setNewPassword = {
          loading: false,
          loaded: true,
          success: true,
          successMessage: action.payload,
        };
      });
    case resetPasswordActions.SET_NEW_PASSWORD_ERROR:
      return produce(state, (draft) => {
        draft.setNewPassword = {
          loading: false,
          loaded: true,
          success: false,
          successMessage: '',
          errorMessage: action.payload,
        };
      });
    case resetPasswordActions.SET_NEW_PASSWORD_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.setNewPassword = {
          loading: false,
          loaded: false,
          success: false,
          successMessage: '',
          errorMessage: '',
        };
      });
    default:
      return state;
  }
};

export default ResetPassword;
