import { all, fork, put, takeLatest, call, delay } from 'redux-saga/effects';
import {
  deleteGroupCleanState,
  deleteGroupError,
  deleteGroupSuccess,
  getGroupsError,
  getGroupsSuccess,
  GROUPS_MANAGEMENT_ACTIONS,
  updateGroupCleanState,
  updateGroupError,
  updateGroupSuccess,
} from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';
import { checkValidToken } from 'src/common/helpers/CheckAuth';

function* getGroups() {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}`;
    const groups = yield call(requests.getRequest, url, {});

    yield put(getGroupsSuccess(groups));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(getGroupsError(GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS_ERROR));
  }
}

function* addGroup({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}`;
    const response = yield call(requests.postRequest, url, payload);
    yield put({
      type: GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_SUCCESS,
      payload: response,
    });
    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Group
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP_ERROR,
    });
  }
}

function* updateGroup({ payload: { groupId, data } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}`;
    yield call(requests.putRequest, url, data);
    yield put(updateGroupSuccess({ groupId, data }));
    toastSuccess(
      TextConstants.Common.EditedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Group
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(updateGroupError(e));
  } finally {
    yield delay(500);
    yield put(updateGroupCleanState());
  }
}

function* deleteGroup({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${payload}`;
    yield call(requests.deleteRequest, url);
    yield put(deleteGroupSuccess(payload));
    toastSuccess(
      TextConstants.Common.DeletedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Group
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(deleteGroupError(e));
  } finally {
    yield delay(500);
    yield put(deleteGroupCleanState());
  }
}

export function* GroupsManagementSaga() {
  yield takeLatest(GROUPS_MANAGEMENT_ACTIONS.GET_GROUPS, getGroups);
  yield takeLatest(GROUPS_MANAGEMENT_ACTIONS.ADD_GROUP, addGroup);
  yield takeLatest(GROUPS_MANAGEMENT_ACTIONS.UPDATE_GROUP, updateGroup);
  yield takeLatest(GROUPS_MANAGEMENT_ACTIONS.DELETE_GROUP, deleteGroup);
}

export default function* rootSaga() {
  yield all([fork(GroupsManagementSaga)]);
}
