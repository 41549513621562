import { all, call, fork, takeLeading, put, select } from 'redux-saga/effects';
import {
  realtimeDataActions,
  getRealtimeDataError,
  getRealtimeDataSuccess,
} from './actions';
import * as requests from '../../helpers/HTTPRequest';
import ApiConstants from '../../helpers/ApiConstants';
import dayjs from 'dayjs';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import { getDashboardSettings } from 'src/views/data/dashboard/actions';
import { toastWarning } from 'src/common/helpers/Notifications';

function* getRealtimeData({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const { start, end, datasets, timeFrame } = payload;

    const dateFormat = 'YYYY-MM-DDTHH:mm:ss.ssssssZ';

    // Get realtime data from API
    const deviceIds = datasets.join('&deviceId=');
    const url = `${ApiConstants.BASE_URL}${
      ApiConstants.REALTIME_DATA
    }?start=${dayjs(start).format(dateFormat)}&end=${dayjs(end).format(
      dateFormat
    )}&deviceId=${deviceIds}`;
    const res = yield call(requests.getRequest, url);

    const currentData = yield select(
      (state) => state.RealtimeData.realtimeData
    );

    // When device id unregistered. Reload dashboard settings
    if (res.statusCode && parseInt(res.statusCode) === 205) {
      toastWarning(res.message);
      yield put(getDashboardSettings())
    } else {
      const data = mergeData(res, currentData, timeFrame);
      yield put(getRealtimeDataSuccess({ data }));
    }
  } catch (e) {
    console.log(e);
    yield put(getRealtimeDataError('Dashboard realtime data load failed'));
  }
}

// Append the data for each unit and channel, up to 30 mins from the current time
function mergeData(newData, oldData, timeFrame) {
  const resultData = {};
  // Get unitIds of both oldData and newData
  const unitIds = Object.keys({ ...oldData, ...newData });
  unitIds.forEach((unitId) => {
    // Get channels of both oldData and newData
    const channels = Object.keys({ ...oldData[unitId], ...newData[unitId] });
    channels.forEach((channel) => {
      const oldChannelData = oldData[unitId] ? oldData[unitId][channel] : [];
      const newChannelData = newData[unitId] ? newData[unitId][channel] : [];
      const combinedChannelData = [...oldChannelData, ...newChannelData];
      const resultarray = Array.from(
        combinedChannelData
          .reduce(
            (map, currentitem) => map.set(currentitem.timestamp, currentitem),
            new Map()
          )
          .values()
      );
      resultarray.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });
      resultData[unitId] = resultData[unitId] || {};
      resultData[unitId][channel] = resultarray.filter(
        (data) => dayjs(data.timestamp) >= dayjs().add(-timeFrame, 'minutes')
      );
    });
  });
  return resultData;
}

export function* watchDashboard() {
  yield takeLeading(realtimeDataActions.GET_REALTIME_DATA, getRealtimeData);
}

export default function* rootSaga() {
  yield all([fork(watchDashboard)]);
}
