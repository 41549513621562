export const LOGGERS_MANAGEMENT_ACTIONS = {
  GET_LOGGERS: 'GET_LOGGERS',
  GET_LOGGERS_SUCCESS: 'GET_LOGGERS_SUCCESS',
  GET_LOGGERS_ERROR: 'GET_LOGGERS_ERROR',
  GET_LOGGERS_CLEAN_STATE: 'GET_LOGGERS_CLEAN_STATE',
  ADD_LOGGER: 'ADD_LOGGER',
  ADD_LOGGER_SUCCESS: 'ADD_LOGGER_SUCCESS',
  ADD_LOGGER_ERROR: 'ADD_LOGGER_ERROR',
  ADD_LOGGER_CLEAN_STATE: 'ADD_LOGGER_CLEAN_STATE',
  UPDATE_LOGGER: 'UPDATE_LOGGER',
  UPDATE_LOGGER_SUCCESS: 'UPDATE_LOGGER_SUCCESS',
  UPDATE_LOGGER_ERROR: 'UPDATE_LOGGER_ERROR',
  UPDATE_LOGGER_CLEAN_STATE: 'UPDATE_LOGGER_CLEAN_STATE',
  DELETE_LOGGER: 'DELETE_LOGGER',
  DELETE_LOGGER_SUCCESS: 'DELETE_LOGGER_SUCCESS',
  DELETE_LOGGER_ERROR: 'DELETE_LOGGER_ERROR',
  DELETE_LOGGER_CLEAN_STATE: 'DELETE_LOGGER_CLEAN_STATE',
};
// =========================================================================
export const getLoggers = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS,
});
export const getLoggersSuccess = (data) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS_SUCCESS,
  payload: data,
});
export const getLoggersError = ({ message }) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS_ERROR,
  payload: message,
});
export const getLoggersCleanState = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.GET_LOGGERS_CLEAN_STATE,
});
// =========================================================================
export const addLogger = (data) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER,
  payload: data,
});
export const addLoggerSuccess = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_SUCCESS,
});
export const addLoggerError = ({ message }) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_ERROR,
  payload: message,
});
export const addLoggerCleanState = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.ADD_LOGGER_CLEAN_STATE,
});
// =========================================================================
export const updateLogger = (data) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER,
  payload: data,
});
export const updateLoggerSuccess = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_SUCCESS,
});
export const updateLoggerError = ({ message }) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_ERROR,
  payload: message,
});
export const updateLoggerCleanState = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.UPDATE_LOGGER_CLEAN_STATE,
});
// =========================================================================
export const deleteLogger = (data) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER,
  payload: data,
});
export const deleteLoggerSuccess = (data) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER_SUCCESS,
  payload: data,
});
export const deleteLoggerError = ({ message }) => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER_ERROR,
  payload: message,
});
export const deleteLoggerCleanState = () => ({
  type: LOGGERS_MANAGEMENT_ACTIONS.DELETE_LOGGER_CLEAN_STATE,
});
// =========================================================================