import produce from 'immer';
import { LAST_SEQUENCE_ACTION } from './actions';

const initialState = {
  nextSequence: { tableName: '', nextSequence: 0 },
  loading: false,
};

const LastSequence = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE_SUCCESS:
      return produce(state, (draft) => {
        draft.nextSequence = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    case LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE_FAILED:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default LastSequence;
