import TextConstants from './TextConstants';

/**
 * @description Get the data type of channel by input is the type number
 * @param {string} type
 * @returns {string} Channel data type
 */
const getChannelDataType = (type) => {
  switch (parseInt(type)) {
    case 0:
      return 'Number';
    case 1:
      return 'Boolean';
    case 2:
      return 'String';
    case 3:
      return 'Binary Data';
    case 4:
      return 'Location Data';
    default:
      return 'Unknown';
  }
};

const getFullName = (user) => {
  let firstName = user.firstName ? user.firstName : '';
  let lastName = user.lastName ? user.lastName : '';
  return `${firstName} ${lastName}`.trim() || 'ー';
};

const ROLE_CST = TextConstants.Common.RolesConst;

const getDefaultPagePath = (role) => {
  switch (role) {
    case ROLE_CST.SUPER_ADMIN:
      return '/users-management';

    case ROLE_CST.COMPANY_ADMIN:
    case ROLE_CST.DEVICE_ADMIN:
    case ROLE_CST.STORE_ADMIN:
    case ROLE_CST.GENERAL_USER:
      return '/dashboard';

    default:
      return '/login';
  }
};

const downloadJsonFileFromObject = (fileName, Obj) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(Obj)
  )}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = fileName;

  link.click();
};

export default {
  getChannelDataType,
  getFullName,
  getDefaultPagePath,
  downloadJsonFileFromObject,
};
