import { all, fork, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  addGroupDevicesSuccess,
  getGroupDevicesError,
  getGroupDevicesSuccess,
  GROUP_DEVICES_ACTIONS,
  unlinkGroupDeviceSuccess,
} from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';
import { checkValidToken } from 'src/common/helpers/CheckAuth';

function* getGroupDevicesSaga({ payload: groupId }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.UNITS}`;
    const devices = yield call(requests.getRequest, url, {});

    yield put(getGroupDevicesSuccess({ groupId, devices }));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(getGroupDevicesError(e?.message));
  }
}

function* addGroupDevicesSaga({ payload: { groupId, data, callback } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.UNITS}`;
    const response = yield call(requests.postRequest, url, data);

    // update device list on UnitsChannels reducer
    const { units } = yield select(state => state.UnitsChannels);
    const devices = units.map(x => response.deviceIds?.includes(x.deviceId) ? ({ ...x, groupId: response.groupId }) : x);

    // map group devices name
    const groupDevices = response.deviceIds?.map(x => {
      const device = units.find(d => d.deviceId === x);
      console.log("🚀 ~ file: saga.js:40 ~ groupDevices ~ device", device)
      if (device) {
        return {
          deviceId: x,
          name: device.name,
        }
      }
      return {
        deviceId: x,
      };
    }) || [];

    yield put(addGroupDevicesSuccess({ units: devices, devices: groupDevices }));

    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Device
      )
    );

    callback && callback();
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_ERROR,
    });
  }
}

function* unlinkGroupDeviceSaga({ payload: { groupId, deviceId, callback } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.UNITS}/${deviceId}`;
    const response = yield call(requests.deleteRequest, url);

    // update device list on UnitsChannels reducer
    const { units } = yield select(state => state.UnitsChannels);
    const devices = units.map(x => !response.deviceIds?.includes(x.deviceId) ? ({ ...x, groupId: null }) : x);

    yield put(unlinkGroupDeviceSuccess({ deviceId, units: devices }));

    toastSuccess(
      TextConstants.Common.DeletedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Device
      )
    );

    callback && callback();
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_ERROR,
    });
  }
}

export function* GroupDevicesManagementSaga() {
  yield takeLatest(GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES, getGroupDevicesSaga);
  yield takeLatest(GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES, addGroupDevicesSaga);
  yield takeLatest(GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE, unlinkGroupDeviceSaga);
}

export default function* rootSaga() {
  yield all([fork(GroupDevicesManagementSaga)]);
}
