import { all } from 'redux-saga/effects';
import LoginSaga from '../views/public/login/saga';
import ResetPasswordSaga from '../views/public/forgot-password/reduxStore/saga';
import ActivateUserSaga from '../views/public/register/reduxStore/saga';
import LastDataSaga from '../views/data/last-data/saga';
import DashboardSaga from '../views/data/dashboard/saga';
import ChannelSettingsSaga from '../views/settings/channel-settings/saga';
import aggregatedDataSaga from '../common/store/aggregatedData/saga';
import RealtimeDataSaga from '../common/store/realtimeData/saga';
import GatewaysSaga from '../common/store/gateways/saga';
import unitsChannelsSaga from '../common/store/unitsChannels/saga';
import LatestDataSaga from '../common/store/latestData/saga';
import { UsersManagementSaga } from 'src/views/users-management/reduxStore/saga';
import CompaniesManagementSaga from '../common/store/company/saga';
import GroupsManagementSaga from '../common/store/group/saga';
import GroupDevicesManagementSaga from '../common/store/groupDevices/saga';
import GroupStoresManagementSaga from '../common/store/groupStores/saga';
import LoggersManagementSaga from '../common/store/logger/saga';
import StoresManagementSaga from '../common/store/store/saga';
import DepartmentsManagementSaga from '../common/store/department/saga';
import LastSequenceSaga from '../common/store/lastSequence/saga';
import VersionSaga from '../common/store/version/saga';

/*
 * Combines all the existing sagas from /common/store/... and /views/...
 */
export default function* rootSaga() {
  yield all([
    LoginSaga(),
    ResetPasswordSaga(),
    ActivateUserSaga(),
    LastDataSaga(),
    DashboardSaga(),
    ChannelSettingsSaga(),
    aggregatedDataSaga(),
    RealtimeDataSaga(),
    GatewaysSaga(),
    unitsChannelsSaga(),
    LatestDataSaga(),
    UsersManagementSaga(),
    GroupsManagementSaga(),
    GroupDevicesManagementSaga(),
    GroupStoresManagementSaga(),
    LoggersManagementSaga(),
    CompaniesManagementSaga(),
    DepartmentsManagementSaga(),
    StoresManagementSaga(),
    LastSequenceSaga(),
    VersionSaga(),
  ]);
}
