import dayjs from 'dayjs';
import _ from 'lodash';

export const validateFormik = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

export const RandomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateRandomPointsBetweenDates = (
  fromDate,
  toDate,
  SelectedChannels
) => {
  // generate dummy graph data
  const graphData = [];

  const start = new Date(fromDate);
  const end = new Date(toDate);
  let loop = new Date(start);
  while (loop <= end) {
    const t = new Date(loop);
    graphData.push([
      new Date(t.getTime() - 1 * 1000),
      ...(SelectedChannels &&
        SelectedChannels.map(() => RandomIntFromInterval(0, 25))),
    ]);
    const newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(newDate);
  }

  return graphData;
};

export const urlQueryFromObject = (filters) => {
  if (!_.isObject || _.isNull(filters)) {
    return '';
  }
  for (const key in filters) {
    const value = String(filters[key]);
    if (_.isEmpty(value) || _.isNull(value)) {
      delete filters[key];
    }
  }
  const searchParams = new URLSearchParams(filters);
  return '?' + searchParams.toString();
};

export const getLatestTimeData = (latestData, timeFrame) => {
  let latestTime = dayjs().add(-timeFrame, 'minutes');
  const deviceIds = Object.keys(latestData);
  deviceIds.forEach((deviceId) => {
    const channels = Object.keys(latestData[deviceId]);
    channels.forEach((channelId) => {
      const latestInChannel = latestData[deviceId][channelId];
      if (latestInChannel && dayjs(latestInChannel.timestamp) > latestTime) {
        latestTime = dayjs(latestInChannel.timestamp);
      }
    });
  });
  return latestTime;
};

export const isValidValue = (value) => {
  return value !== null && value !== undefined;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
