import { performLogout } from 'src/views/public/login/actions';
import { getUserToken } from './StorageUtils';
import { checkValidToken } from './CheckAuth';
import TextConstants from 'src/common/helpers/TextConstants';
import { store } from "src/init/store";

export const getRequest = (url, params, authenticatedRequest) => {
  return httpRequest('get', url, params, authenticatedRequest);
};

export const putRequest = (url, params, authenticatedRequest) => {
  return httpRequest('put', url, params, authenticatedRequest);
};

export const patchRequest = (url, params, authenticatedRequest) => {
  return httpRequest('PATCH', url, params, authenticatedRequest);
};

export const postRequest = (url, params, authenticatedRequest) => {
  return httpRequest('post', url, params, authenticatedRequest);
};

export const deleteRequest = (url, params, authenticatedRequest) => {
  return httpRequest('delete', url, params, authenticatedRequest);
};

const httpRequest = async (method, url, params, authenticatedRequest = true) => {
  let token;
  if (authenticatedRequest) {
    token = getUserToken();
    if (!checkValidToken(token)) {
      console.log('Token expired!');
    }
  }

  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token && { Authorization: 'Bearer ' + token }),
    },
    method,
    ...(params &&
      method.toLowerCase() !== 'get' && { body: JSON.stringify(params) }),
  };

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        return new Promise((resolve) =>
          response.text().then((resText) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json: resText === '' || !response.ok ? {} : JSON.parse(resText),
              message: resText === '' ? '' : JSON.parse(resText).message,
              data: resText === '' ? '' : JSON.parse(resText).data,
            });
          })
        );
      })
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        } else if (response.status === 401) {
          if (response.message === 'The incoming token has expired') {
            return reject({
              ...response,
              message: TextConstants.Common.SessionExpired,
            });
          } else if (response.message === 'Unauthorized') {
            store.dispatch(performLogout(true))
          }
          return reject(response);
        } else if (response.status === 403) {
          // TODO : perform authorization or redirected to login
          return reject(response);
        } else {
          return reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
