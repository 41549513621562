import produce from 'immer';
import { GROUP_STORES_ACTIONS } from './actions';

const initialState = {
  groupId: null,
  stores: [],
  loading: false,
  type: '',
};

const GroupStoresManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case GROUP_STORES_ACTIONS.GET_GROUP_STORES:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_STORES_ACTIONS.GET_GROUP_STORES_SUCCESS:
      return produce(state, (draft) => {
        draft.groupId = payload.groupId;
        draft.stores = payload.stores;
        draft.loading = false;
        draft.type = actionType;
      });
    case GROUP_STORES_ACTIONS.GET_GROUP_STORES_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUP_STORES_ACTIONS.ADD_GROUP_STORES:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_STORES_ACTIONS.ADD_GROUP_STORES_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.stores = [...payload.newStores, ...state.stores];
      });
    case GROUP_STORES_ACTIONS.ADD_GROUP_STORES_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.stores = payload.stores;
      });
    case GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    case GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.stores = state.stores.filter(x => x.storeId !== payload.storeId);
      });
    case GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });

    default:
      return state;
  }
};

export default GroupStoresManagement;
