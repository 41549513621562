export const GROUP_DEVICES_ACTIONS = {
  GET_GROUP_DEVICES: 'GET_GROUP_DEVICES',
  GET_GROUP_DEVICES_SUCCESS: 'GET_GROUP_DEVICES_SUCCESS',
  GET_GROUP_DEVICES_ERROR: 'GET_GROUP_DEVICES_ERROR',
  GET_GROUP_DEVICES_CLEAN_STATE: 'GET_GROUP_DEVICES_CLEAN_STATE',
  ADD_GROUP_DEVICES: 'ADD_GROUP_DEVICES',
  ADD_GROUP_DEVICES_SUCCESS: 'ADD_GROUP_DEVICES_SUCCESS',
  ADD_GROUP_DEVICES_ERROR: 'ADD_GROUP_DEVICES_ERROR',
  ADD_GROUP_DEVICES_CLEAN_STATE: 'ADD_GROUP_DEVICES_CLEAN_STATE',
  UNLINK_GROUP_DEVICE: 'UNLINK_GROUP_DEVICE',
  UNLINK_GROUP_DEVICE_SUCCESS: 'UNLINK_GROUP_DEVICE_SUCCESS',
  UNLINK_GROUP_DEVICE_ERROR: 'UNLINK_GROUP_DEVICE_ERROR',
  UNLINK_GROUP_DEVICE_CLEAN_STATE: 'UNLINK_GROUP_DEVICE_CLEAN_STATE',
};
export const getGroupDevices = (params) => ({
  type: GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES,
  payload: params,
});
export const getGroupDevicesSuccess = (data) => ({
  type: GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES_SUCCESS,
  payload: data,
});
export const getGroupDevicesError = ({ message }) => ({
  type: GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES_ERROR,
  payload: message,
});
export const getGroupDevicesCleanState = () => ({
  type: GROUP_DEVICES_ACTIONS.GET_GROUP_DEVICES_CLEAN_STATE,
});
// =========================================================================
export const addGroupDevices = (params) => ({
  type: GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES,
  payload: params,
});
export const addGroupDevicesSuccess = (data) => ({
  type: GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_SUCCESS,
  payload: data,
});
export const addGroupDevicesError = ({ message }) => ({
  type: GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_ERROR,
  payload: message,
});
export const addGroupDevicesCleanState = () => ({
  type: GROUP_DEVICES_ACTIONS.ADD_GROUP_DEVICES_CLEAN_STATE,
});
// =========================================================================
export const unlinkGroupDevice = (params) => ({
  type: GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE,
  payload: params,
});
export const unlinkGroupDeviceSuccess = (data) => ({
  type: GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE_SUCCESS,
  payload: data,
});
export const unlinkGroupDeviceError = ({ message }) => ({
  type: GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE_ERROR,
  payload: message,
});
export const unlinkGroupDeviceCleanState = () => ({
  type: GROUP_DEVICES_ACTIONS.UNLINK_GROUP_DEVICE_CLEAN_STATE,
});
// =========================================================================