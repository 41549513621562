const dashboardSettings = {
  width: 1280,
  height: 800
};

const controls = [
  {
    channelData: { id: 'temp' },
    color: '#ff0000',
    name: 'control-1',
    width: 180.0,
    x: 20.0,
    unitId: '12345678ABCD',
    depthIndex: 0.0,
    y: 20.0,
    type: 'single-point',
    height: 180.0,
  },
  {
    channelData: [{ id: 'temp', color: '#ffff00' }],
    color: '#00ff00',
    name: 'control-2',
    width: 80.0,
    x: 100.0,
    unitId: '12345678ABCD',
    depthIndex: 0.0,
    y: 20.0,
    type: 'line-graph',
    height: 80.0,
  },
  {
    channelData: { id: 'alert' },
    color: '#0000ff',
    name: 'control-3',
    width: 80.0,
    x: 200.0,
    unitId: '12345678ABCD',
    depthIndex: 0.0,
    y: 20.0,
    type: 'single-point',
    height: 80.0
  }
]

const data = {
  '29031A88511B': {
    temp: [
      { timestamp: '2021-08-23T23:29:01.359472+00:00', data: 0.17547965026857348 },
      { timestamp: '2021-08-23T23:29:11.387024+00:00', data: 0.7022100415831347 },
      { timestamp: '2021-08-23T23:29:21.415038+00:00', data: 0.37900406318658775 },
      { timestamp: '2021-08-23T23:29:31.448179+00:00', data: 0.6321576679829671 },
      { timestamp: '2021-08-23T23:29:41.838748+00:00', data: 0.6101484518980825 },
      { timestamp: '2021-08-23T23:29:51.867575+00:00', data: 0.9780967646095695 },
      { timestamp: '2021-08-23T23:30:01.889892+00:00', data: 0.6831139713848658 }
    ],
    alert: [
      { timestamp: '2021-08-23T23:29:01.359472+00:00', data: false },
      { timestamp: '2021-08-23T23:29:11.387024+00:00', data: false },
      { timestamp: '2021-08-23T23:29:21.415038+00:00', data: true },
      { timestamp: '2021-08-23T23:29:31.448179+00:00', data: true },
      { timestamp: '2021-08-23T23:29:41.838748+00:00', data: false },
      { timestamp: '2021-08-23T23:29:51.867575+00:00', data: false },
      { timestamp: '2021-08-23T23:30:01.889892+00:00', data: true }
    ]
  },
  '29031A88511C': {
    pressure: [
      { timestamp: '2021-08-23T23:29:01.359472+00:00', data: 300 },
      { timestamp: '2021-08-23T23:29:11.387024+00:00', data: 350 },
      { timestamp: '2021-08-23T23:29:21.415038+00:00', data: 400 },
      { timestamp: '2021-08-23T23:29:31.448179+00:00', data: 450 },
      { timestamp: '2021-08-23T23:29:41.838748+00:00', data: 500 },
      { timestamp: '2021-08-23T23:29:51.867575+00:00', data: 550 },
      { timestamp: '2021-08-23T23:30:01.889892+00:00', data: 600 }
    ],
    humid: [
      { timestamp: '2021-08-23T23:29:01.359472+00:00', data: 0.17547965026857348 },
      { timestamp: '2021-08-23T23:29:11.387024+00:00', data: 0.7022100415831347 },
      { timestamp: '2021-08-23T23:29:21.415038+00:00', data: 0.37900406318658775 },
      { timestamp: '2021-08-23T23:29:31.448179+00:00', data: 0.6321576679829671 },
      { timestamp: '2021-08-23T23:29:41.838748+00:00', data: 0.6101484518980825 },
      { timestamp: '2021-08-23T23:29:51.867575+00:00', data: 0.9780967646095695 },
      { timestamp: '2021-08-23T23:30:01.889892+00:00', data: 0.6831139713848658 }
    ]
  }
}

const units = [
  {
    unitId: '29031A88511B',
    name: 'U1',
    channels: [
      { channelId: 'temp', name: 'Temperature' },
      { channelId: 'alert', name: 'Temperature Alert' }
    ],
  },
  {
    unitId: '29031A88511C',
    name: '',
    channels: [
      { channelId: 'pressure', name: 'Pressure' },
      { channelId: 'humid', name: 'Humid' }
    ],
  }
]

const channels = [
  {
    unitID: '29031A88511B',
    name: 'Unit1',
    channels: [
      { channelId: 'channel_1', name: 'Temperature', type: 'Number', unit: 'C', action: '' },
      { channelId: 'channel_2', name: 'Alert', type: 'Boolean', unit: '', action: '' },
      { channelId: 'channel_3', name: 'Status', type: 'String', unit: '', action: '' },
      { channelId: 'channel_4', name: '', type: 'Number', unit: '', action: '' },
      { channelId: 'channel_5', name: 'Alert', type: '', unit: '', action: '' },
      { channelId: 'channel_6', name: '', type: '', unit: '', action: '' },
    ]
  },
  {
    unitId: '29031A88511C',
    name: 'Unit2',
    channels: [
      { channelId: 'channel_1', name: 'Temperature', type: 'Number', unit: 'C', action: '' },
      { channelId: 'channel_2', name: '', type: '', unit: '', action: '' },
      { channelId: 'channel_3', name: 'Status', type: 'String', unit: '', action: '' },
      { channelId: 'channel_4', name: 'Alert', type: '', unit: '', action: '' },
    ]
  },
]

export const dashboardMocks = {
  dashboardSettings,
  controls,
  data,
  units,
  channels
};
