import produce from 'immer';
import { realtimeDataActions } from './actions';

const initialState = {
  realtimeData: [],
  loading: false,
  loaded: false,
  loadingError: ''
};

const RealtimeData = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case realtimeDataActions.GET_REALTIME_DATA:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case realtimeDataActions.GET_REALTIME_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.realtimeData = payload.data;
        draft.loading = false;
        draft.loaded = true;
      });
    case realtimeDataActions.GET_REALTIME_DATA_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.loadingError = payload;
      });
    case realtimeDataActions.GET_REALTIME_DATA_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingError = '';
      });
    default:
      return state;
  }
}

export default RealtimeData;
