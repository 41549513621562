import {
  all,
  fork,
  takeLeading,
  delay,
  put,
  call,
  select,
} from 'redux-saga/effects';
import * as Actions from './actions';
import * as requests from '../../helpers/HTTPRequest';
import ApiConstants from '../../helpers/ApiConstants';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import TextConstants from 'src/common/helpers/TextConstants';
import { getDashboardSettings } from 'src/views/data/dashboard/actions';
import { toastError, toastWarning } from 'src/common/helpers/Notifications';

function* getLatestData({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const { datasets } = payload;
    if (datasets && datasets.length > 0) {
      const params = new URLSearchParams();
      datasets.forEach((deviceId) => {
        params.append('deviceId', deviceId);
      });
      const url = `${ApiConstants.BASE_URL}${ApiConstants.LAST_DATA
        }?${params.toString()}`;

      let apiRes = yield call(requests.getRequest, url, {});
      yield put(Actions.getLatestDataSuccess(apiRes));
    } else {
      // toastWarning('NO DEVICES FOUND!');
      console.log('WARNING: NO DEVICES FOUND!');
    }
  } catch (e) {
    // When device id unregistered. Reload devices...
    if (e.status === 404) {
      const currentDevices = yield select((state) => state.UnitsChannels.units);
      const deviceIdsUnregistered = e.data?.devicesUnregistered || [];
      const devicesNameArr = [];
      deviceIdsUnregistered.forEach((id) => {
        const dv = currentDevices.find((item) => item.deviceId === id);
        const deviceName =
          dv && dv.name ? dv.name : `${TextConstants.Common.NoName}(id: ${id})`;
        devicesNameArr.push(deviceName);
      });
      const devicesName = devicesNameArr.join('、') + '。';
      toastWarning(`${e.message}${TextConstants.Common.DeviceNotRegistered}${devicesName}`);
      yield put(getDashboardSettings());
    } else {
      toastError(e.message, TextConstants.Common.Warning);
      yield put(Actions.getLatestDataError('loading failed'));
    }
  } finally {
    yield delay(500);
    yield put(Actions.getLatestDataCleanState());
  }
}

export function* latestData() {
  yield takeLeading(Actions.latestDataActions.GET_LATEST_DATA, getLatestData);
}

export default function* rootSaga() {
  yield all([fork(latestData)]);
}
