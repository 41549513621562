import produce from 'immer';
import { loginActions } from '../../../views/public/login/actions';
import { getUserToken } from '../../helpers/StorageUtils';

const initialState = {
  idToken: getUserToken(),
};

const broadcastChannel = new BroadcastChannel('AUTH_CHANNEL');

const User = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case loginActions.LOGIN_SUCCESS:
      broadcastChannel.postMessage({ actionType });
      return produce(state, (draft) => {
        draft.idToken = payload.idToken;
      });
    case loginActions.LOGOUT_SUCCESS:
    case loginActions.TOKEN_EXPIRED_SUCCESS:
      broadcastChannel.postMessage({ actionType });
      return produce(state, (draft) => {
        draft.idToken = null;
      });
    default:
      return state;
  }
};

export default User;
