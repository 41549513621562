export const presetColors = [
  '#000000',
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
];

export const defaultWidth = 1280;
export const defaultHeight = 768;
export const defaultTimeFrame = 30;

export const dashboardMinWidth = 1024;
export const dashboardMinHeight = 768;
export const dashboardMaxWidth = 7680;
export const dashboardMaxHeight = 4320;
