import { CHANNEL_SETTINGS_ACTION } from './actions';

const defaultState = {
  channelData: [],
  loading: false,
  loadingError: '',
  saving: false,
  savingSuccess: false,
  savingError: false,
  savingErrorMessage: '',
  addSuccess: false,
  addError: false,
  addLoading: false,
  deleteSuccess: false,
  deleteError: false,
  deleteLoading: false,
}

const ChannelSettingsReducer = (state = defaultState, action) => {
  const { type: actionType, payload, status } = action;
  switch (actionType) {
    case CHANNEL_SETTINGS_ACTION.GET_DATA:
      return {
        ...state,
        loading: true,
      }
    case CHANNEL_SETTINGS_ACTION.GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        channelData: action.data,
      }
    case CHANNEL_SETTINGS_ACTION.GET_DATA_ERROR:
      return {
        ...state,
        loading: false,
        loadingError: payload,
      }
    case CHANNEL_SETTINGS_ACTION.SAVE_DATA:
      return {
        ...state,
        channelData: payload,
        saving: true,
        savingSuccess: false,
        savingError: false,
      }
    case CHANNEL_SETTINGS_ACTION.SAVE_DATA_SUCCESS:
      return {
        ...state,
        saving: false,
        savingSuccess: true,
        savingError: false,
      }
    case CHANNEL_SETTINGS_ACTION.SAVE_DATA_ERROR:
      return {
        ...state,
        saving: false,
        savingSuccess: false,
        savingError: true,
        savingErrorMessage: payload,
        savingErrorStatus: status,
      }
    case CHANNEL_SETTINGS_ACTION.SAVE_DATA_CLEAN_STATE:
      return {
        ...state,
        saving: false,
        savingSuccess: false,
        savingError: false,
        savingErrorMessage: '',
      }
    case CHANNEL_SETTINGS_ACTION.ADD_CHANNEL:
      return {
        ...state,
        channelData: payload,
        addLoading: true,
        addSuccess: false,
        addError: false,
      }
    case CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addSuccess: true,
        addError: false,
      }
    case CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_ERROR:
      return {
        ...state,
        saving: false,
        savingSuccess: false,
        savingError: true,
        savingErrorMessage: payload,
      }
    case CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_CLEAN_STATE:
      return {
        ...state,
        addLoading: false,
        addSuccess: false,
        addError: false,
        savingErrorMessage: '',
      }
    case CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL:
      return {
        ...state,
        channelData: payload,
        deleteLoading: true,
        deleteSuccess: false,
        deleteError: false,
      }
    case CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
        deleteError: false,
      }
    case CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: true,
        savingErrorMessage: payload,
        deleteErrorStatus: status,
      }
    case CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_CLEAN_STATE:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        savingErrorMessage: '',
      }
    default:
      return state;
  }
}

export default ChannelSettingsReducer;
