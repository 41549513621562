import produce from 'immer';
import _ from 'lodash';
import { USERS_MANAGEMENT_ACTION } from './actions';

const initialState = {
  users: [],
  loading: false,
  type: '',
  updateUserLoading: false,
  resendInvitationMailLoading: false,
};

const UsersManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case USERS_MANAGEMENT_ACTION.GET_USERS:
    case USERS_MANAGEMENT_ACTION.ADD_USER:
    case USERS_MANAGEMENT_ACTION.SAVE_USER:
    case USERS_MANAGEMENT_ACTION.DELETE_USER:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS:
      return produce(state, (draft) => {
        draft.updateUserLoading = true;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL:
      return produce(state, (draft) => {
        draft.resendInvitationMailLoading = true;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.GET_USERS_SUCCESS:
      return produce(state, (draft) => {
        draft.users = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.ADD_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.users = [...payload, ...state.users];
        draft.loading = false;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.SAVE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.users = state.users.map((user) =>
          user.username === payload.username ? payload : user
        );
        draft.loading = false;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.DELETE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.users = state.users.filter(
          (user) => user.username !== payload.username
        );
        draft.loading = false;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS_SUCCESS:
      const usersCopied = _.cloneDeep(state.users);
      usersCopied.map((user) => {
        if (user.email === payload.email) {
          user.status = payload.status;
        }
      });
      return produce(state, (draft) => {
        draft.users = usersCopied;
        draft.updateUserLoading = false;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL_SUCCESS:
    case USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL_ERROR:
      return produce(state, (draft) => {
        draft.resendInvitationMailLoading = false;
        draft.type = actionType;
      });

    case USERS_MANAGEMENT_ACTION.GET_USERS_ERROR:
    case USERS_MANAGEMENT_ACTION.ADD_USER_ERROR:
    case USERS_MANAGEMENT_ACTION.SAVE_USER_ERROR:
    case USERS_MANAGEMENT_ACTION.DELETE_USER_ERROR:
    case USERS_MANAGEMENT_ACTION.SAVE_USER_CLEAN_STATE:
    case USERS_MANAGEMENT_ACTION.ADD_USER_CLEAN_STATE:
    case USERS_MANAGEMENT_ACTION.DELETE_USER_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
      });
    case USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS_ERROR:
      return produce(state, (draft) => {
        draft.updateUserLoading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default UsersManagement;
