import { LAST_DATA_ACTION } from './actions';

const defaultState = {
  channelData: [],
  loading: false,
}

const lastDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LAST_DATA_ACTION.GET_DATA:
      return {
        ...state,
        loading: true
      }
    case LAST_DATA_ACTION.GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        channelData: action.data,
      }
    default:
      return state;
  }
}

export default lastDataReducer;
