import { combineReducers } from 'redux';
import Login from '../views/public/login/reducer';
import ResetPassword from '../views/public/forgot-password/reduxStore/reducer';
import ActivateUser from '../views/public/register/reduxStore/reducer';
import Dashboard from '../views/data/dashboard/reducer';
import LastData from '../views/data/last-data/reducer';
import User from '../common/store/user/reducers';
import ChannelSettings from '../views/settings/channel-settings/reducer';
import AggregatedData from '../common/store/aggregatedData/reducer';
import RealtimeData from '../common/store/realtimeData/reducers';
import Gateways from '../common/store/gateways/reducer';
import UnitsChannels from '../common/store/unitsChannels/reducer';
import LatestData from '../common/store/latestData/reducer';
import UsersManagement from '../views/users-management/reduxStore/reducer';
import GroupsManagement from '../common/store/group/reducer';
import GroupDevicesManagement from '../common/store/groupDevices/reducer';
import GroupStoresManagement from '../common/store/groupStores/reducer';
import LoggersManagement from '../common/store/logger/reducer';
import CompaniesManagement from '../common/store/company/reducer';
import StoresManagement from '../common/store/store/reducer';
import DepartmentsManagement from '../common/store/department/reducer';
import Theme from '../common/store/theme/reducer';
import LastSequence from '../common/store/lastSequence/reducer';
import Version from '../common/store/version/reducer';

import { loginActions } from 'src/views/public/login/actions';
/*
 * Combines all the existing reducers from /common/store/... and /views/...
 */
const reducers = combineReducers({
  Login,
  ResetPassword,
  ActivateUser,
  Dashboard,
  LastData,
  User,
  ChannelSettings,
  AggregatedData,
  RealtimeData,
  Gateways,
  UnitsChannels,
  LatestData,
  UsersManagement,
  GroupsManagement,
  GroupDevicesManagement,
  GroupStoresManagement,
  LoggersManagement,
  CompaniesManagement,
  StoresManagement,
  DepartmentsManagement,
  Theme,
  LastSequence,
  Version,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === loginActions.LOGOUT_SUCCESS) state = undefined;
  return reducers(state, action);
};

export default rootReducer;
