import { all, fork, takeLeading, put, call, delay } from 'redux-saga/effects';
import {
  registeredActions,
  activateUserSuccess,
  activateUserError,
  activateUserCleanState,
} from './actions';
import * as requests from '../../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';

function* activateUser({ payload }) {
  try {
    const requestBody = { ...payload };
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ACTIVE_USERS}`;
    const apiRes = yield call(requests.postRequest, url, requestBody, false);
    if (apiRes) {
      toastSuccess(apiRes?.message || TextConstants.Common.Success);
      yield put(activateUserSuccess());
    } else {
      toastError('Oops! something went wrong!');
      yield put(activateUserError({ message: 'Oops! something went wrong!' }));
    }
  } catch (error) {
    console.log(error);
    toastError(error?.message);
    yield put(activateUserError({ message: error?.message || '' }));
  } finally {
    yield delay(500);
    yield put(activateUserCleanState());
  }
}

export function* watchActivateUser() {
  yield takeLeading(registeredActions.ACTIVATE_USER, activateUser);
}

export default function* rootSaga() {
  yield all([fork(watchActivateUser)]);
}
