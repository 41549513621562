import { all, fork, put, takeLatest, call, delay } from 'redux-saga/effects';
import { CHANNEL_SETTINGS_ACTION } from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';
import { checkValidToken } from 'src/common/helpers/CheckAuth';

function* saveData({ payload: data }) {
  try {
    // check valid token
    yield checkValidToken();

    // const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}/${data.unitId}/channels/${data.channelId}`
    const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}/${data.unitId}/channels/${data.channelId}`;
    const apiRes = yield call(requests.patchRequest, url, data.channel);
    console.log(apiRes);

    yield put({ type: CHANNEL_SETTINGS_ACTION.SAVE_DATA_SUCCESS });

    toastSuccess(TextConstants.Common.EditedSuccessMessage.replace('{item}', TextConstants.Common.Channel));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: CHANNEL_SETTINGS_ACTION.SAVE_DATA_ERROR,
      payload: e.message,
      status: e.status,
    });
  } finally {
    yield delay(500);
    yield put({ type: CHANNEL_SETTINGS_ACTION.SAVE_DATA_CLEAN_STATE });
  }
}

function* addChannel({ payload: channelData }) {
  try {
    // check valid token
    yield checkValidToken();

    // check valid token
    yield delay(500);

    console.log('add new channel saga', channelData);
    yield put({ type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_SUCCESS });
    toastSuccess(TextConstants.Common.AddedSuccessMessage.replace('{item}', TextConstants.Common.Channel));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_ERROR,
      payload: e.message,
    });
  } finally {
    yield delay(500);
    yield put({ type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_CLEAN_STATE });
  }
}

function* deleteChannel({ payload: { deviceId, channelId } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.UNITS}/${deviceId}/channels/${channelId}`
    yield call(requests.deleteRequest, url);

    yield put({ type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_SUCCESS, payload: { deviceId, channelId } });

    toastSuccess(TextConstants.Common.DeletedSuccessMessage.replace('{item}', TextConstants.Common.Channel));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_ERROR,
      payload: e.message,
      status: e.status,
    });
  } finally {
    yield delay(500);
    yield put({ type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_CLEAN_STATE });
  }
}

export function* channelSettingsSaga() {
  yield takeLatest(CHANNEL_SETTINGS_ACTION.SAVE_DATA, saveData);
  yield takeLatest(CHANNEL_SETTINGS_ACTION.ADD_CHANNEL, addChannel);
  yield takeLatest(CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL, deleteChannel);
}

export default function* rootSaga() {
  yield all([fork(channelSettingsSaga)]);
}
