import { all, fork, takeLeading, put, call, delay } from 'redux-saga/effects';
import {
  loginActions,
  performLoginSuccess,
  performLoginError,
  performLoginCleanState,
  performLogoutSuccess,
  performLogoutError,
  performLogoutCleanState,
  performTokenExpiredSuccess,
} from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import {
  setUserToken,
  setUserInfo,
} from '../../../common/helpers/StorageUtils';
import TextConstants from 'src/common/helpers/TextConstants';

function* loginUser({ payload }) {
  try {
    const requestBody = { ...payload };
    const url = `${ApiConstants.BASE_URL}${ApiConstants.LOGIN_URL}`;
    const apiRes = yield call(requests.postRequest, url, requestBody, false);
    if (apiRes) {
      // store user token
      setUserToken(apiRes.idToken);
      setUserInfo(apiRes.user);
      yield put(performLoginSuccess(apiRes));
    } else {
      yield put(performLoginError({ message: 'Oops! something went wrong!' }));
    }
  } catch (error) {
    console.log(error);
    yield put(performLoginError({ message: error?.message || '' }));
  } finally {
    yield delay(2000);
    yield put(performLoginCleanState());
  }
}

function* logoutUser({ payload }) {
  try {
    const { isTokenExpired } = payload;
    // clear all from storage
    localStorage.clear();
    if (isTokenExpired) {
      yield put(
        performTokenExpiredSuccess(TextConstants.Common.SessionExpired)
      );
    } else {
      yield put(
        performLogoutSuccess(TextConstants.Common.loggedOutSuccessfully)
      );
    }
  } catch (error) {
    yield put(performLogoutError());
  } finally {
    yield delay(3500);
    yield put(performLogoutCleanState());
  }
}

export function* watchLoginUser() {
  yield takeLeading(loginActions.LOGIN, loginUser);
  yield takeLeading(loginActions.LOGOUT, logoutUser);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser)]);
}
