
export const CHANNEL_SETTINGS_ACTION = {
  GET_DATA: 'CHANNEL_SETTINGS.GET_DATA',
  GET_DATA_SUCCESS: 'CHANNEL_SETTINGS.GET_DATA_SUCCESS',
  GET_DATA_ERROR: 'CHANNEL_SETTINGS.GET_DATA_ERROR',
  SAVE_DATA: 'CHANNEL_SETTINGS.SAVE_DATA',
  SAVE_DATA_SUCCESS: 'CHANNEL_SETTINGS.SAVE_DATA_SUCCESS',
  SAVE_DATA_ERROR: 'CHANNEL_SETTINGS.SAVE_DATA_ERROR',
  SAVE_DATA_CLEAN_STATE: 'CHANNEL_SETTINGS.SAVE_DATA_CLEAN_STATE',
  ADD_CHANNEL: 'ADD_CHANNEL',
  ADD_CHANNEL_SUCCESS: 'ADD_CHANNEL_SUCCESS',
  ADD_CHANNEL_ERROR: 'ADD_CHANNEL_ERROR',
  ADD_CHANNEL_CLEAN_STATE: 'ADD_CHANNEL_CLEAN_STATE',
  DELETE_CHANNEL: 'DELETE_CHANNEL',
  DELETE_CHANNEL_SUCCESS: 'DELETE_CHANNEL_SUCCESS',
  DELETE_CHANNEL_ERROR: 'DELETE_CHANNEL_ERROR',
  DELETE_CHANNEL_CLEAN_STATE: 'DELETE_CHANNEL_CLEAN_STATE',
}

export const getChannelSettings = () => ({
  type: CHANNEL_SETTINGS_ACTION.GET_DATA,
});

export const saveChannelSettings = (data) => ({
  type: CHANNEL_SETTINGS_ACTION.SAVE_DATA,
  payload: data,
})

export const addChannel = (data) => ({
  type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL,
  payload: data
})
export const addChannelSuccess = () => ({
  type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_SUCCESS
});
export const addChannelError = ({ message }) => ({
  type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_ERROR,
  payload: message,
});
export const addChannelCleanState = () => ({
  type: CHANNEL_SETTINGS_ACTION.ADD_CHANNEL_CLEAN_STATE,
});

export const deleteChannel = (data) => ({
  type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL,
  payload: data
})
export const deleteChannelSuccess = () => ({
  type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_SUCCESS
});
export const deleteChannelError = ({ message }) => ({
  type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_ERROR,
  payload: message,
});
export const deleteChannelCleanState = () => ({
  type: CHANNEL_SETTINGS_ACTION.DELETE_CHANNEL_CLEAN_STATE,
});
