import { all, fork, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  addGroupStoresError,
  addGroupStoresSuccess,
  editGroupStorePermissionError,
  editGroupStorePermissionSuccess,
  getGroupStoresError,
  getGroupStoresSuccess,
  GROUP_STORES_ACTIONS,
  unlinkGroupStoreError,
  unlinkGroupStoreSuccess,
} from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';
import { checkValidToken } from 'src/common/helpers/CheckAuth';

function* getGroupStoresSaga({ payload: groupId }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.STORES_MANAGEMENT}`;
    const stores = yield call(requests.getRequest, url, {});

    yield put(getGroupStoresSuccess({ groupId, stores }));
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(getGroupStoresError(e?.message));
  }
}

function* addGroupStoresSaga({ payload: { groupId, data, callback } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.STORES_MANAGEMENT}`;
    const newStores = yield call(requests.postRequest, url, data);

    // update stores list in reducer
    // add group to store locally
    const { stores } = yield select(state => state.StoresManagement);
    const updatedStores = stores.map(x => {
      const store = newStores.find(s => s.storeId === x.storeId);
      if (store) {
        return {
          ...x,
          groups: [...x.groups, { groupId, permission: store.permission }]
        }
      }
      return x;
    });

    yield put(addGroupStoresSuccess({ newStores, stores: updatedStores }));

    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Store
      )
    );

    callback && callback();
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(addGroupStoresError());
  }
}

function* editGroupStorePermissionSaga({ payload: { groupId, storeId, data, callback } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.STORES_MANAGEMENT}/${storeId}`;
    yield call(requests.putRequest, url, data);

    // update stores list in reducer
    const { stores } = yield select(state => state.GroupStoresManagement);
    const updatedStores = stores.map(x => x.storeId === storeId ? ({ ...x, permission: data.permission }) : x);

    yield put(editGroupStorePermissionSuccess({ stores: updatedStores }));

    toastSuccess(
      TextConstants.Common.EditedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Store
      )
    );

    callback && callback();
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(editGroupStorePermissionError());
  }
}

function* unlinkGroupStoreSaga({ payload: { groupId, storeId, callback } }) {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.GROUPS_MANAGEMENT}/${groupId}/${ApiConstants.STORES_MANAGEMENT}/${storeId}`;
    yield call(requests.deleteRequest, url);

    // update stores list in reducer
    // add group to store locally
    const { stores } = yield select(state => state.StoresManagement);
    const updatedStores = stores.map(x => x.storeId === storeId ? ({ ...x, groups: x.groups?.filter(g => g.groupId !== groupId) }) : x);

    yield put(unlinkGroupStoreSuccess({ storeId, stores: updatedStores }));

    toastSuccess(
      TextConstants.Common.DeletedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.Device
      )
    );

    callback && callback();
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(unlinkGroupStoreError());
  }
}

export function* GroupStoresManagementSaga() {
  yield takeLatest(GROUP_STORES_ACTIONS.GET_GROUP_STORES, getGroupStoresSaga);
  yield takeLatest(GROUP_STORES_ACTIONS.ADD_GROUP_STORES, addGroupStoresSaga);
  yield takeLatest(GROUP_STORES_ACTIONS.EDIT_GROUP_STORE_PERMISSION, editGroupStorePermissionSaga);
  yield takeLatest(GROUP_STORES_ACTIONS.UNLINK_GROUP_STORE, unlinkGroupStoreSaga);
}

export default function* rootSaga() {
  yield all([fork(GroupStoresManagementSaga)]);
}
