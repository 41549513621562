import { delay, put } from 'redux-saga/effects';
import { parseJwt } from './';
import { getUserToken } from './StorageUtils';
import { performLogout } from 'src/views/public/login/actions';
import dayjs from 'dayjs';

function* checkValidToken() {
  // get the user's token
  const userToken = getUserToken();
  if (!userToken) {
    yield put(performLogout(true));
  }
  // parse the token payload and get the expiry time
  const parseToken = parseJwt(userToken);
  if (parseToken && parseToken.exp) {
    const expTime = dayjs(parseToken.exp * 1000);
    const currTime = dayjs();
    if (!currTime.isBefore(expTime)) {
      // dispatch logout action
      yield put(performLogout(true));
    }
  }
}

export { checkValidToken };
