import { NotificationManager } from 'react-notifications';
import TextConstants from 'src/common/helpers/TextConstants';
import dayjs from 'dayjs';

export const toastError = (message, title=null, timeout=0) => {
  if (title == null) {
    const currentTime = dayjs().format('HH:mm:ss'); 
    title = `${TextConstants.Common.Error} ${currentTime}`;
  }
  NotificationManager.error(message, title, timeout, null, true);
}

export const toastSuccess = (message, title=TextConstants.Common.Success, timeout=3500) => {
  NotificationManager.success(message, title, timeout, null, true);
}

export const toastWarning = (message, title=TextConstants.Common.Warning, timeout=8000) => {
  NotificationManager.warning(message, title, timeout, null, true);
}

export const clearAllToasts = () => {
  NotificationManager.listNotify.forEach((n) =>
    NotificationManager.remove({ id: n.id })
  );
};
