import TextConstants from "src/common/helpers/TextConstants";

const ControlTypes = {
  POINT_DATA: 'single-point',
  GRAPH_DATA: 'graph',
  LAMP: 'lamp',
  PIN: 'pin',
  TABLE: 'table',
  BG_IMAGE: 'bg-image',
}

const ControlTypesList = [
  { id: ControlTypes.POINT_DATA, label: TextConstants.Common.PointType },
  { id: ControlTypes.LAMP, label: TextConstants.Common.LampType },
  { id: ControlTypes.PIN, label: TextConstants.Common.PinType },
]

const ControlTypeSize = {
  [ControlTypes.POINT_DATA]: { 
    defWidth: 180,
    defHeight: 90,
    minWidth: 180,
    minHeight: 90,
    heightWithDateTime: 144,
  },
  [ControlTypes.GRAPH_DATA]: { 
    defWidth: 318,
    defHeight: 180,
    minWidth: 318,
    minHeight: 180,
  },
  [ControlTypes.LAMP]: { 
    defWidth: 180,
    defHeight: 144,
    minWidth: 180,
    minHeight: 144,
  },
  [ControlTypes.PIN]: { 
    defWidth: 180,
    defHeight: 144,
    minWidth: 180,
    minHeight: 60 + 24 * 1,
  },
  [ControlTypes.TABLE]: { 
    defWidth: 450,
    defHeight: 145,
    minWidth: 450,
    minHeight: 145,
  },
  [ControlTypes.BG_IMAGE]: { 
    defWidth: 180,
    defHeight: 144,
    minWidth: 180,
    minHeight: 144,
  },
};

const minDimensions = {
  height: 144,
  width: 160,
};

export { ControlTypes, ControlTypesList, ControlTypeSize, minDimensions };
