const aggregatedDataActions = {
  FETCH_DATA_VIEW: 'FETCH_DATA_VIEW',
  FETCH_DATA_VIEW_SUCCESS: 'FETCH_DATA_VIEW_SUCCESS',
  FETCH_DATA_VIEW_ERROR: 'FETCH_DATA_VIEW_ERROR',
  FETCH_DATA_VIEW_CLEAN_STATE: 'FETCH_DATA_VIEW_CLEAN_STATE',
};

const fetchDataView = (data) => ({
  type: aggregatedDataActions.FETCH_DATA_VIEW,
  payload: data,
});
const fetchDataViewSuccess = (data) => ({
  type: aggregatedDataActions.FETCH_DATA_VIEW_SUCCESS,
  payload: data,
});
const fetchDataViewError = ({ message }) => ({
  type: aggregatedDataActions.FETCH_DATA_VIEW_ERROR,
  payload: message,
});
const fetchDataViewCleanState = () => ({
  type: aggregatedDataActions.FETCH_DATA_VIEW_CLEAN_STATE,
});

export {
  aggregatedDataActions,
  fetchDataView,
  fetchDataViewSuccess,
  fetchDataViewError,
  fetchDataViewCleanState,
};
