import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import { LAST_SEQUENCE_ACTION } from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';

function* getNextSequenceValue({ payload }) {
  try {
    const tableName = payload.tableName;
    const url = `${
      ApiConstants.BASE_URL
    }${ApiConstants.GET_NEXT_SEQUENCE_VALUE.replace(
      '{:tableName}',
      tableName
    )}`;
    const apiRes = yield call(requests.getRequest, url, {});
    yield put({
      type: LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE_SUCCESS,
      payload: apiRes,
    });
  } catch (e) {
    console.log('Warning: Get the next sequence number is failed!');
    yield put({
      type: LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE_FAILED,
    });
  }
}

export function* LastSequence() {
  yield takeLatest(
    LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE,
    getNextSequenceValue
  );
}

export default function* rootSaga() {
  yield all([fork(LastSequence)]);
}
