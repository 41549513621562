import produce from 'immer';
import { DEPARTMENTS_MANAGEMENT_ACTION } from './actions';

const initialState = {
  departments: [],
  loading: false,
  type: '',
};

const DepartmentsManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case DEPARTMENTS_MANAGEMENT_ACTION.GET_DEPARTMENTS:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case DEPARTMENTS_MANAGEMENT_ACTION.GET_DEPARTMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.departments = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default DepartmentsManagement;
