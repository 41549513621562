const gatewaysActions = {
  FETCH_GATEWAYS: 'FETCH_GATEWAYS',
  FETCH_GATEWAYS_SUCCESS: 'FETCH_GATEWAYS_SUCCESS',
  FETCH_GATEWAYS_ERROR: 'FETCH_GATEWAYS_ERROR',
  FETCH_GATEWAYS_CLEAN_STATE: 'FETCH_GATEWAYS_CLEAN_STATE',
  SAVE_GATEWAY_CONFIG: 'SAVE_GATEWAY_CONFIG',
  SAVE_GATEWAY_CONFIG_SUCCESS: 'SAVE_GATEWAY_CONFIG_SUCCESS',
  SAVE_GATEWAY_CONFIG_ERROR: 'SAVE_GATEWAY_CONFIG_ERROR',
  SAVE_GATEWAY_CONFIG_CLEAN_STATE: 'SAVE_GATEWAY_CONFIG_CLEAN_STATE',
  ADD_GATEWAY: 'ADD_GATEWAY',
  ADD_GATEWAY_SUCCESS: 'ADD_GATEWAY_SUCCESS',
  ADD_GATEWAY_ERROR: 'ADD_GATEWAY_ERROR',
  ADD_GATEWAY_CLEAN_STATE: 'ADD_GATEWAY_CLEAN_STATE',
  DELETE_GATEWAY: 'DELETE_GATEWAY',
  DELETE_GATEWAY_SUCCESS: 'DELETE_GATEWAY_SUCCESS',
  DELETE_GATEWAY_ERROR: 'DELETE_GATEWAY_ERROR',
  DELETE_GATEWAY_CLEAN_STATE: 'DELETE_GATEWAY_CLEAN_STATE',
};

const fetchGateways = (data) => ({
  type: gatewaysActions.FETCH_GATEWAYS,
  payload: data,
});
const fetchGatewaysSuccess = (data) => ({
  type: gatewaysActions.FETCH_GATEWAYS_SUCCESS,
  payload: data,
});
const fetchGatewaysError = ({ message }) => ({
  type: gatewaysActions.FETCH_GATEWAYS_ERROR,
  payload: message,
});
const fetchGatewaysCleanState = () => ({
  type: gatewaysActions.FETCH_GATEWAYS_CLEAN_STATE,
});
const saveGatewayConfig = (data) => ({
  type: gatewaysActions.SAVE_GATEWAY_CONFIG,
  payload: data,
});
const saveGatewayConfigSuccess = () => ({
  type: gatewaysActions.SAVE_GATEWAY_CONFIG_SUCCESS,
});
const saveGatewayConfigError = ({ message }) => ({
  type: gatewaysActions.SAVE_GATEWAY_CONFIG_ERROR,
  payload: message,
});
const saveGatewayConfigCleanState = () => ({
  type: gatewaysActions.SAVE_GATEWAY_CONFIG_CLEAN_STATE,
});
const addGateway = (data) => ({
  type: gatewaysActions.ADD_GATEWAY,
  payload: data,
});
const addGatewaySuccess = () => ({
  type: gatewaysActions.ADD_GATEWAY_SUCCESS,
});
const addGatewayError = ({ message }) => ({
  type: gatewaysActions.ADD_GATEWAY_ERROR,
  payload: message,
});
const addGatewayCleanState = () => ({
  type: gatewaysActions.ADD_GATEWAY_CLEAN_STATE,
});
const deleteGateway = (data) => ({
  type: gatewaysActions.DELETE_GATEWAY,
  payload: data,
});
const deleteGatewaySuccess = () => ({
  type: gatewaysActions.DELETE_GATEWAY_SUCCESS,
});
const deleteGatewayError = ({ message }) => ({
  type: gatewaysActions.DELETE_GATEWAY_ERROR,
  payload: message,
});
const deleteGatewayCleanState = () => ({
  type: gatewaysActions.DELETE_GATEWAY_CLEAN_STATE,
});

export {
  gatewaysActions,
  fetchGateways,
  fetchGatewaysSuccess,
  fetchGatewaysError,
  fetchGatewaysCleanState,
  saveGatewayConfig,
  saveGatewayConfigSuccess,
  saveGatewayConfigError,
  saveGatewayConfigCleanState,
  addGateway,
  addGatewaySuccess,
  addGatewayError,
  addGatewayCleanState,
  deleteGateway,
  deleteGatewaySuccess,
  deleteGatewayError,
  deleteGatewayCleanState,
};
