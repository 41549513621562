const unitsChannelsActions = {
  FETCH_UNITS: 'FETCH_UNITS',
  FETCH_UNITS_SUCCESS: 'FETCH_UNITS_SUCCESS',
  FETCH_UNITS_ERROR: 'FETCH_UNITS_ERROR',
  FETCH_UNITS_CLEAN_STATE: 'FETCH_UNITS_CLEAN_STATE',
  FETCH_DEVICE_CHANNELS: 'FETCH_DEVICE_CHANNELS',
  FETCH_DEVICE_CHANNELS_SUCCESS: 'FETCH_DEVICE_CHANNELS_SUCCESS',
  FETCH_DEVICE_CHANNELS_ERROR: 'FETCH_DEVICE_CHANNELS_ERROR',
  SAVE_UNIT_CONFIG: 'SAVE_UNIT_CONFIG',
  SAVE_UNIT_CONFIG_SUCCESS: 'SAVE_UNIT_CONFIG_SUCCESS',
  SAVE_UNIT_CONFIG_ERROR: 'SAVE_UNIT_CONFIG_ERROR',
  SAVE_UNIT_CONFIG_CLEAN_STATE: 'SAVE_UNIT_CONFIG_CLEAN_STATE',
  ADD_UNIT: 'ADD_UNIT',
  ADD_UNIT_SUCCESS: 'ADD_UNIT_SUCCESS',
  ADD_UNIT_ERROR: 'ADD_UNIT_ERROR',
  ADD_UNIT_CLEAN_STATE: 'ADD_UNIT_CLEAN_STATE',
  DELETE_UNIT: 'DELETE_UNIT',
  DELETE_UNIT_SUCCESS: 'DELETE_UNIT_SUCCESS',
  DELETE_UNIT_ERROR: 'DELETE_UNIT_ERROR',
  DELETE_UNIT_CLEAN_STATE: 'DELETE_UNIT_CLEAN_STATE',
};

const fetchUnits = (data) => ({
  type: unitsChannelsActions.FETCH_UNITS,
  payload: data,
});
const fetchUnitsSuccess = (data) => ({
  type: unitsChannelsActions.FETCH_UNITS_SUCCESS,
  payload: data,
});
const fetchUnitsError = ({ message }) => ({
  type: unitsChannelsActions.FETCH_UNITS_ERROR,
  payload: message,
});
const fetchUnitsCleanState = () => ({
  type: unitsChannelsActions.FETCH_UNITS_CLEAN_STATE,
});
const fetchDeviceChannels = (data) => ({
  type: unitsChannelsActions.FETCH_DEVICE_CHANNELS,
  payload: data,
});
const fetchDeviceChannelsSuccess = (data) => ({
  type: unitsChannelsActions.FETCH_DEVICE_CHANNELS_SUCCESS,
  payload: data,
});
const fetchDeviceChannelsError = () => ({
  type: unitsChannelsActions.FETCH_DEVICE_CHANNELS_ERROR,
});
const saveUnitConfig = (data) => ({
  type: unitsChannelsActions.SAVE_UNIT_CONFIG,
  payload: data,
});
const saveUnitConfigSuccess = () => ({
  type: unitsChannelsActions.SAVE_UNIT_CONFIG_SUCCESS,
});
const saveUnitConfigError = ({ message }) => ({
  type: unitsChannelsActions.SAVE_UNIT_CONFIG_ERROR,
  payload: message,
});
const saveUnitConfigCleanState = () => ({
  type: unitsChannelsActions.SAVE_UNIT_CONFIG_CLEAN_STATE,
});
const addUnit = (data) => ({
  type: unitsChannelsActions.ADD_UNIT,
  payload: data,
});
const addUnitSuccess = () => ({
  type: unitsChannelsActions.ADD_UNIT_SUCCESS,
});
const addUnitError = ({ message }) => ({
  type: unitsChannelsActions.ADD_UNIT_ERROR,
  payload: message,
});
const addUnitCleanState = () => ({
  type: unitsChannelsActions.ADD_UNIT_CLEAN_STATE,
});
const deleteUnit = (data) => ({
  type: unitsChannelsActions.DELETE_UNIT,
  payload: data,
});
const deleteUnitSuccess = () => ({
  type: unitsChannelsActions.DELETE_UNIT_SUCCESS,
});
const deleteUnitError = ({ message }) => ({
  type: unitsChannelsActions.DELETE_UNIT_ERROR,
  payload: message,
});
const deleteUnitCleanState = () => ({
  type: unitsChannelsActions.DELETE_UNIT_CLEAN_STATE,
});

export {
  unitsChannelsActions,
  fetchUnits,
  fetchUnitsSuccess,
  fetchUnitsError,
  fetchUnitsCleanState,
  fetchDeviceChannels,
  fetchDeviceChannelsSuccess,
  fetchDeviceChannelsError,
  saveUnitConfig,
  saveUnitConfigSuccess,
  saveUnitConfigError,
  saveUnitConfigCleanState,
  addUnit,
  addUnitSuccess,
  addUnitError,
  addUnitCleanState,
  deleteUnit,
  deleteUnitSuccess,
  deleteUnitError,
  deleteUnitCleanState,
};
