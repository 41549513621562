const registeredActions = {
  ACTIVATE_USER: 'ACTIVATE_USER',
  ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_ERROR: 'ACTIVATE_USER_ERROR',
  ACTIVATE_USER_CLEAN_STATE: 'ACTIVATE_USER_CLEAN_STATE',
};

const activateUser = (payload) => ({
  type: registeredActions.ACTIVATE_USER,
  payload,
});
const activateUserSuccess = (data) => ({
  type: registeredActions.ACTIVATE_USER_SUCCESS,
  payload: data,
});
const activateUserError = ({ message }) => ({
  type: registeredActions.ACTIVATE_USER_ERROR,
  payload: message,
});
const activateUserCleanState = () => ({
  type: registeredActions.ACTIVATE_USER_CLEAN_STATE,
});

export {
  registeredActions,
  activateUser,
  activateUserSuccess,
  activateUserError,
  activateUserCleanState,
};
