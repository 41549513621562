import {
  all,
  fork,
  takeLeading,
  delay,
  put,
  call,
  select,
} from 'redux-saga/effects';
import * as Actions from './actions';
import dayjs from 'dayjs';
import { fetchUnits } from '../unitsChannels/actions';
import * as requests from '../../helpers/HTTPRequest';
import ApiConstants from '../../helpers/ApiConstants';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastWarning, toastError } from 'src/common/helpers/Notifications';

function* fetchDataView({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const {
      unit,
      selectedChannels,
      from,
      to,
      dataType,
      startTime,
      endTime,
      deviceChannelsInfoSelected,
    } = payload;

    const graphDataSets = {};

    // const dateFormat = 'YYYY-MM-DDTHH:mm:ss.ssssssZ';
    let startParam = from;
    let endParam = to;

    let dateFormat = 'YYYY-MM-DD';
    if (dataType === 'raw-data') {
      dateFormat = 'YYYY-MM-DD HH:mm';
      startParam = from + ' ' + startTime;
      endParam = to + ' ' + endTime;
    }

    const url = `${ApiConstants.BASE_URL}${
      ApiConstants.DATA_VIEW
    }?type=${dataType.replace('-data', '')}&start=${dayjs(startParam).format(
      dateFormat
    )}&end=${dayjs(endParam).format(
      dateFormat
    )}&deviceId=${unit}&channelIds=${selectedChannels.join('&channelIds=')}`;
    const apiRes = yield call(requests.getRequest, url, {});

    Object.entries(apiRes).forEach(([channelId, aggregatedData]) => {
      graphDataSets[channelId] = [];
      const channelDetail = deviceChannelsInfoSelected.find(
        (item) => item.channelId === channelId
      );
      const isNumberType = channelDetail && channelDetail.dataType === 0;
      aggregatedData.map((point) => {
        const t = new Date(point.date || point.timestamp);
        dataType === 'daily-data' && t.setHours(0, 0, 0);
        let prepareArr;
        if (dataType === 'raw-data') {
          // when channel data type is Number
          if (isNumberType) {
            prepareArr = [
              new Date(t),
              parseFloat(Number(point.value).toFixed(2)),
            ];
          } else {
            prepareArr = [new Date(t), point.value];
          }
        } else {
          prepareArr = [
            new Date(t),
            parseFloat(Number(point.avg).toFixed(2)),
            parseFloat(Number(point.min).toFixed(2)),
            parseFloat(Number(point.max).toFixed(2)),
          ];
        }
        graphDataSets[channelId].push(prepareArr);
      });
    });
    yield put(
      Actions.fetchDataViewSuccess({
        data: graphDataSets,
      })
    );
  } catch (e) {
    console.log('e3', e);
    // When device id unregistered. Reload devices...
    if (e.status === 404) {
      const currentDevices = yield select((state) => state.UnitsChannels.units);
      const deviceIdUnregistered = e.data?.deviceIdUnregistered || '';
      const dv = currentDevices.find(
        (item) => item.deviceId === deviceIdUnregistered
      );
      const deviceName =
        dv && dv.name
          ? dv.name
          : `${TextConstants.Common.NoName}(id: ${deviceIdUnregistered})`;
      toastWarning(
        `${e.message}${TextConstants.Common.DeviceNotRegistered}${deviceName}。`
      );
      yield put(fetchUnits());
    } else if (e.status === 504) {
      // "Endpoint request timed out"
      yield put(
        Actions.fetchDataViewError({
          message: TextConstants.DataView.EndpointTimeOutError,
        })
      );
    } else {
      yield put(
        Actions.fetchDataViewError({ message: e?.message || 'loading failed' })
      );
    }
  } finally {
    yield delay(2500);
    yield put(Actions.fetchDataViewCleanState());
  }
}

export function* watchDataView() {
  yield takeLeading(
    Actions.aggregatedDataActions.FETCH_DATA_VIEW,
    fetchDataView
  );
}

export default function* rootSaga() {
  yield all([fork(watchDataView)]);
}
