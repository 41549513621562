import { all, fork, put, takeLatest, call, delay } from 'redux-saga/effects';
import { USERS_MANAGEMENT_ACTION } from './actions';
import { getStores } from 'src/common/store/store/actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import Utils from 'src/common/helpers/Utils';
import TextConstants from 'src/common/helpers/TextConstants';
import CommonConstants from 'src/common/helpers/CommonConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';

function* getUser() {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.USERS_MANAGEMENT}`;
    const apiRes = yield call(requests.getRequest, url, {});
    yield put({
      type: USERS_MANAGEMENT_ACTION.GET_USERS_SUCCESS,
      payload: apiRes.map((u) => {
        return {
          ...u,
          fullName: Utils.getFullName(u),
          companyName: u.companyName || 'ー',
        };
      }),
    });
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: USERS_MANAGEMENT_ACTION.GET_USERS_ERROR,
      payload: 'USERS_MANAGEMENT_ACTION.GET_USERS_ERROR',
    });
  }
}
function* saveData({ payload: userData }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.USERS_MANAGEMENT}/${userData.username}`;
    const apiRes = yield call(requests.patchRequest, url, userData);
    console.log('save user data apiRes, ', apiRes);
    yield put({
      type: USERS_MANAGEMENT_ACTION.SAVE_USER_SUCCESS,
      payload: apiRes,
    });
    toastSuccess(
      TextConstants.Common.EditedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.User
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: USERS_MANAGEMENT_ACTION.SAVE_USER_ERROR,
      payload: 'USERS_MANAGEMENT_ACTION.SAVE_DATA_ERROR',
    });
  } finally {
    yield delay(500);
    yield put({ type: USERS_MANAGEMENT_ACTION.SAVE_USER_CLEAN_STATE });
  }
}
function* addUser({ payload: userData }) {
  try {
    console.log('addUser payload', userData);
    const url = `${ApiConstants.BASE_URL}${ApiConstants.USERS_MANAGEMENT}`;
    const apiRes = yield call(requests.postRequest, url, userData, true);

    // when add new store and admin succesfully, fetch the store list again
    if (
      userData.actionType &&
      userData.actionType === CommonConstants.CREATE_STORE_AND_ADMIN
    ) {
      yield put(getStores());
    }

    yield put({
      type: USERS_MANAGEMENT_ACTION.ADD_USER_SUCCESS,
      payload: apiRes?.map((u) => ({
        ...u,
        fullName: Utils.getFullName(u),
        companyName: u.name || 'ー',
      })),
    });
    toastSuccess(
      TextConstants.Common.AddedSuccessMessage.replace(
        '{item}',
        TextConstants.Common.User
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: USERS_MANAGEMENT_ACTION.ADD_USER_ERROR,
    });
  } finally {
    yield delay(500);
    yield put({ type: USERS_MANAGEMENT_ACTION.ADD_USER_CLEAN_STATE });
  }
}
function* deleteUser({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.USERS_MANAGEMENT}`;
    const apiRes = yield call(
      requests.deleteRequest,
      url,
      { email: payload.email },
      true
    );
    yield put({
      type: USERS_MANAGEMENT_ACTION.DELETE_USER_SUCCESS,
      payload: apiRes,
    });
    toastSuccess(
      TextConstants.Common.DeletedSuccessMessage.replace(
        '{item}',
        payload.email
      )
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: USERS_MANAGEMENT_ACTION.DELETE_USER_ERROR,
    });
  } finally {
    yield delay(500);
    yield put({ type: USERS_MANAGEMENT_ACTION.DELETE_USER_CLEAN_STATE });
  }
}

function* updateUserStatus({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.UPDATE_USER_STATUS}`;
    yield call(requests.patchRequest, url, payload, true);
    yield put({
      type: USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS_SUCCESS,
      payload: payload,
    });
    toastSuccess(
      TextConstants.Common.EditedSuccessMessage.replace('{item}', payload.email)
    );
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS_ERROR,
    });
  } finally {
  }
}

function* resendInvitationMail({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.RESEND_INVITATION_MAIL}`;
    yield call(requests.postRequest, url, payload, true);
    yield put({
      type: USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL_SUCCESS,
      payload: payload,
    });
    toastSuccess(TextConstants.Common.EmailSentSuccessfullyMsg);
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put({
      type: USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL_ERROR,
    });
  } finally {
  }
}

export function* UsersManagementSaga() {
  yield takeLatest(USERS_MANAGEMENT_ACTION.GET_USERS, getUser);
  yield takeLatest(USERS_MANAGEMENT_ACTION.ADD_USER, addUser);
  yield takeLatest(USERS_MANAGEMENT_ACTION.SAVE_USER, saveData);
  yield takeLatest(USERS_MANAGEMENT_ACTION.DELETE_USER, deleteUser);
  yield takeLatest(
    USERS_MANAGEMENT_ACTION.UPDATE_USER_STATUS,
    updateUserStatus
  );
  yield takeLatest(
    USERS_MANAGEMENT_ACTION.RESEND_INVITATION_MAIL,
    resendInvitationMail
  );
}

export default function* rootSaga() {
  yield all([fork(UsersManagementSaga)]);
}
