import produce from 'immer';
import { COMPANIES_MANAGEMENT_ACTION } from './actions';

const initialState = {
  companies: [],
  loading: false,
  type: '',
};

const CompaniesManagement = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case COMPANIES_MANAGEMENT_ACTION.GET_COMPANIES:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case COMPANIES_MANAGEMENT_ACTION.GET_COMPANIES_SUCCESS:
      return produce(state, (draft) => {
        draft.companies = payload;
        draft.loading = false;
        draft.type = actionType;
      });
    default:
      return state;
  }
};

export default CompaniesManagement;
