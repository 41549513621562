import produce from 'immer';
import { dashboardActions } from './actions';

const initialState = {
  dashboardSettings: {},
  dashboardControls: [],
  dashboardBackgroundImageUrl: '',
  loading: false,
  loaded: false,
  loadingError: '',
  type: '',
  isControlDeleted: false,
  isControlEdited: false,
  saving: false,
  saveSuccess: false,
  saveError: false,
  saveErrorMessage: '',
};

const Login = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case dashboardActions.GET_DASHBOARD_SETTINGS:
      return produce(state, (draft) => {
        draft.dashboardSettings = {};
        draft.dashboardControls = [];
        draft.loading = true;
      });
    case dashboardActions.GET_DASHBOARD_SETTINGS_SUCCESS:
      return produce(state, (draft) => {
        draft.dashboardSettings = payload.settings || {};
        draft.dashboardControls = payload.controls || [];
        draft.loading = false;
        draft.loaded = true;
        draft.dashboardBackgroundImageUrl = payload.backgroundImageUrl;
      });
    case dashboardActions.GET_DASHBOARD_SETTINGS_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.loadingError = payload;
      });
    case dashboardActions.GET_DASHBOARD_SETTINGS_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingError = '';
      });

    case dashboardActions.SAVE_DASHBOARD_SETTINGS:
      return produce(state, (draft) => {
        draft.saving = true;
        draft.saveSuccess = false;
        draft.saveError = false;
      });
    case dashboardActions.SAVE_DASHBOARD_SETTINGS_SUCCESS:
      return produce(state, (draft) => {
        draft.saving = false;
        draft.saveSuccess = true;
        draft.saveError = false;
      });
    case dashboardActions.SAVE_DASHBOARD_SETTINGS_ERROR:
      return produce(state, (draft) => {
        draft.saving = false;
        draft.saveSuccess = false;
        draft.saveError = true;
      });
    case dashboardActions.SAVE_DASHBOARD_SETTINGS_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.saving = false;
        draft.saveSuccess = false;
        draft.saveError = false;
        draft.saveErrorMessage = payload;
      });

    case dashboardActions.ADD_CONTROL:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
      });
    case dashboardActions.ADD_CONTROL_SUCCESS:
      return produce(state, (draft) => {
        draft.dashboardControls = payload.controls;
        draft.loading = false;
        draft.type = actionType;
      });
    case dashboardActions.ADD_CONTROL_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingError = payload;
        draft.type = actionType;
      });
    case dashboardActions.ADD_CONTROL_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loadingError = '';
        draft.type = '';
      });

    case dashboardActions.DELETE_CONTROL:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = actionType;
        draft.loadingError = '';
        draft.isControlDeleted = false;
      });
    case dashboardActions.DELETE_CONTROL_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.isControlDeleted = true;
      });
    case dashboardActions.DELETE_CONTROL_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.loadingError = payload;
        draft.isControlDeleted = false;
      });
    case dashboardActions.DELETE_CONTROL_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = actionType;
        draft.loadingError = '';
        draft.isControlDeleted = false;
      });

    case dashboardActions.EDIT_CONTROL:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = false;
        draft.loading = true;
      });
    case dashboardActions.EDIT_CONTROL_SUCCESS:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = true;
        draft.loading = false;
        draft.dashboardControls = payload.controls;
      });
    case dashboardActions.EDIT_CONTROL_ERROR:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = false;
        draft.loading = false;
      });
    case dashboardActions.EDIT_CONTROL_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = false;
        draft.loading = false;
      });

    case dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE:
      return produce(state, (draft) => {
        draft.type = actionType;
      });
    case dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE_SUCCESS:
      return produce(state, (draft) => {
        draft.type = actionType;
      });
    case dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE_ERROR:
      return produce(state, (draft) => {
        draft.type = actionType;
      });
    case dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.type = actionType;
      });

    case dashboardActions.UPDATE_DRAGGED_CONTROL:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.dashboardControls = payload;
      });
    case dashboardActions.SET_PIN_DESTINATION:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = false;
        draft.loading = true;
      });
    case dashboardActions.SET_PIN_DESTINATION_SUCCESS:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = true;
        draft.loading = false;
        draft.dashboardControls = payload.controls;
      });
    case dashboardActions.SET_PIN_DESTINATION_ERROR:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = false;
        draft.loading = false;
      });
    case dashboardActions.SET_PIN_DESTINATION_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.type = actionType;
        draft.isControlEdited = false;
        draft.loading = false;
      });
    case dashboardActions.UDPATE_FONT_SIZE:
      return produce(state, (draft) => {
        draft.dashboardControls = state.dashboardControls.map((controls) => {
          const controlsCopy = { ...controls };
          if (controlsCopy.id === payload.controlId) {
            controlsCopy.fontSize = payload.fontSize;
            controlsCopy.indexKey = new Date().valueOf();
          }
          return controlsCopy;
        });
      });
    default:
      return state;
  }
};

export default Login;
