export const STORES_MANAGEMENT_ACTIONS = {
  GET_STORES: 'GET_STORES',
  GET_STORES_SUCCESS: 'GET_STORES_SUCCESS',
  GET_STORES_ERROR: 'GET_STORES_ERROR',
  ADD_STORE: 'ADD_STORE',
  ADD_STORE_SUCCESS: 'ADD_STORE_SUCCESS',
  ADD_STORE_ERROR: 'ADD_STORE_ERROR',
  ADD_STORE_CLEAN_STATE: 'ADD_STORE_CLEAN_STATE',
  UPDATE_STORE: 'UPDATE_STORE',
  UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  UPDATE_STORE_ERROR: 'UPDATE_STORE_ERROR',
  UPDATE_STORE_CLEAN_STATE: 'UPDATE_STORE_CLEAN_STATE',
  DELETE_STORE: 'DELETE_STORE',
  DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  DELETE_STORE_ERROR: 'DELETE_STORE_ERROR',
  DELETE_STORE_CLEAN_STATE: 'DELETE_STORE_CLEAN_STATE',
};
// =========================================================================
export const getStores = () => ({
  type: STORES_MANAGEMENT_ACTIONS.GET_STORES,
});
// =========================================================================
export const addStore = (data) => ({
  type: STORES_MANAGEMENT_ACTIONS.ADD_STORE,
  payload: data,
});
export const addStoreSuccess = (params) => ({
  type: STORES_MANAGEMENT_ACTIONS.ADD_STORE_SUCCESS,
  payload: params,
});
export const addStoreError = ({ message }) => ({
  type: STORES_MANAGEMENT_ACTIONS.ADD_STORE_ERROR,
  payload: message,
});
export const addStoreCleanState = () => ({
  type: STORES_MANAGEMENT_ACTIONS.ADD_STORE_CLEAN_STATE,
});
// =========================================================================
export const updateStore = (data) => ({
  type: STORES_MANAGEMENT_ACTIONS.UPDATE_STORE,
  payload: data,
});
export const updateStoreSuccess = (params) => ({
  type: STORES_MANAGEMENT_ACTIONS.UPDATE_STORE_SUCCESS,
  payload: params,
});
export const updateStoreError = ({ message }) => ({
  type: STORES_MANAGEMENT_ACTIONS.UPDATE_STORE_ERROR,
  payload: message,
});
export const updateStoreCleanState = () => ({
  type: STORES_MANAGEMENT_ACTIONS.UPDATE_STORE_CLEAN_STATE,
});
// =========================================================================
export const deleteStore = (data) => ({
  type: STORES_MANAGEMENT_ACTIONS.DELETE_STORE,
  payload: data,
});
export const deleteStoreSuccess = (params) => ({
  type: STORES_MANAGEMENT_ACTIONS.DELETE_STORE_SUCCESS,
  payload: params,
});
export const deleteStoreError = ({ message }) => ({
  type: STORES_MANAGEMENT_ACTIONS.DELETE_STORE_ERROR,
  payload: message,
});
export const deleteStoreCleanState = () => ({
  type: STORES_MANAGEMENT_ACTIONS.DELETE_STORE_CLEAN_STATE,
});
