import produce from 'immer';
import { gatewaysActions } from './actions';

const initialState = {
  gateways: [],
  loading: false,
  loaded: false,
  errorMessage: '',
  actionType: '',
  success: false,
  fetchError: false,
  saveSuccess: false,
  saveError: false,
  saveLoading: false,
  addSuccess: false,
  addError: false,
  addLoading: false,
  deleteSuccess: false,
  deleteError: false,
  deleteLoading: false,
};

const DataView = (state = initialState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case gatewaysActions.FETCH_GATEWAYS:
      return produce(state, (draft) => {
        draft.gateways = [];
        draft.loading = true;
        draft.loaded = false;
        draft.errorMessage = '';
        draft.success = false;
        draft.fetchError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.FETCH_GATEWAYS_SUCCESS:
      return produce(state, (draft) => {
        draft.gateways = payload;
        draft.loading = false;
        draft.loaded = true;
        draft.success = true;
        draft.fetchError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.FETCH_GATEWAYS_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
        draft.errorMessage = payload;
        draft.success = false;
        draft.fetchError = true;
        draft.actionType = actionType;
      });
    case gatewaysActions.FETCH_GATEWAYS_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.errorMessage = '';
        draft.success = false;
        draft.fetchError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.SAVE_GATEWAY_CONFIG:
      return produce(state, (draft) => {
        draft.saveLoading = true;
        draft.loaded = false;
        draft.errorMessage = '';
        draft.saveSuccess = false;
        draft.saveError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.SAVE_GATEWAY_CONFIG_SUCCESS:
      return produce(state, (draft) => {
        draft.saveLoading = false;
        draft.loaded = true;
        draft.saveSuccess = true;
        draft.saveError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.SAVE_GATEWAY_CONFIG_ERROR:
      return produce(state, (draft) => {
        draft.saveLoading = false;
        draft.loaded = true;
        draft.errorMessage = payload;
        draft.saveSuccess = false;
        draft.saveError = true;
        draft.actionType = actionType;
      });
    case gatewaysActions.SAVE_GATEWAY_CONFIG_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.saveLoading = false;
        draft.errorMessage = '';
        draft.saveSuccess = false;
        draft.saveError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.ADD_GATEWAY:
      return produce(state, (draft) => {
        draft.addLoading = true;
        draft.loaded = false;
        draft.errorMessage = '';
        draft.addSuccess = false;
        draft.addError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.ADD_GATEWAY_SUCCESS:
      return produce(state, (draft) => {
        draft.addLoading = false;
        draft.loaded = true;
        draft.addSuccess = true;
        draft.addError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.ADD_GATEWAY_ERROR:
      return produce(state, (draft) => {
        draft.addLoading = false;
        draft.loaded = true;
        draft.errorMessage = payload;
        draft.addSuccess = false;
        draft.addError = true;
        draft.actionType = actionType;
      });
    case gatewaysActions.ADD_GATEWAY_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.addLoading = false;
        draft.errorMessage = '';
        draft.deleteSuccess = false;
        draft.deleteError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.DELETE_GATEWAY:
      return produce(state, (draft) => {
        draft.deleteLoading = true;
        draft.loaded = false;
        draft.errorMessage = '';
        draft.deleteSuccess = false;
        draft.deleteError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.DELETE_GATEWAY_SUCCESS:
      return produce(state, (draft) => {
        draft.deleteLoading = false;
        draft.loaded = true;
        draft.deleteSuccess = true;
        draft.deleteError = false;
        draft.actionType = actionType;
      });
    case gatewaysActions.DELETE_GATEWAY_ERROR:
      return produce(state, (draft) => {
        draft.deleteLoading = false;
        draft.loaded = true;
        draft.errorMessage = payload;
        draft.deleteSuccess = false;
        draft.deleteError = true;
        draft.actionType = actionType;
      });
    case gatewaysActions.DELETE_GATEWAY_CLEAN_STATE:
      return produce(state, (draft) => {
        draft.deleteLoading = false;
        draft.errorMessage = '';
        draft.deleteError = false;
        draft.deleteError = false;
        draft.actionType = actionType;
      });
    default:
      return state;
  }
};

export default DataView;
