import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import { VERSION_ACTION } from './actions';
import * as requests from '../../../common/helpers/HTTPRequest';
import ApiConstants from '../../../common/helpers/ApiConstants';

function* getBackendVersion() {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.BACKEND_VERSION}`;
    const apiRes = yield call(requests.getRequest, url, {});
    yield put({
      type: VERSION_ACTION.GET_BACKEND_VERSION_SUCCESS,
      payload: apiRes,
    });
  } catch (e) {
    console.log('Warning: Get the backend version is failed!');
    yield put({
      type: VERSION_ACTION.GET_BACKEND_VERSION_FAILED,
    });
  }
}

export function* Version() {
  yield takeLatest(VERSION_ACTION.GET_BACKEND_VERSION, getBackendVersion);
}

export default function* rootSaga() {
  yield all([fork(Version)]);
}
