import {
  all,
  call,
  fork,
  takeLeading,
  put,
  select,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import ApiConstants from 'src/common/helpers/ApiConstants';
import {
  dashboardActions,
  getDashboardSettingsError,
  getDashboardSettingsSuccess,
  saveDashboardSettingsError,
  saveDashboardSettingsSuccess,
  saveDashboardSettingsCleanState,
  addControlCleanState,
  addControlError,
  addControlSuccess,
  editControlError,
  editControlSuccess,
  getDashboardSettingsCleanState,
  deleteControlSuccess,
  deleteControlError,
  updateDraggedControl,
  editControlPositionAndSizeSuccess,
  editControlPositionAndSizeError,
  editControlPositionAndSizeCleanState,
  setPinDestinationSuccess,
  setPinDestinationError,
} from './actions';
import * as requests from 'src/common/helpers/HTTPRequest';
import { checkValidToken } from 'src/common/helpers/CheckAuth';
import TextConstants from 'src/common/helpers/TextConstants';
import { toastError, toastSuccess } from 'src/common/helpers/Notifications';
import { minDimensions } from './components/controls/control-types/ControlTypes';
import { defaultHeight, defaultWidth, defaultTimeFrame } from './constants';

function* getDashboardSettings() {
  try {
    // check valid token
    yield checkValidToken();

    const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
    const { settings, controls, backgroundImageUrl } = yield call(
      requests.getRequest,
      url
    );

    const controlsAddedIndexKey = controls
      ? controls.map((c) => {
          c.indexKey = c.indexKey ? c.indexKey : c.id;
          c.isShowDateTime =
            c.isShowDateTime === undefined || c.isShowDateTime === null
              ? true
              : c.isShowDateTime;
          return c;
        })
      : [];
    if (Object.keys(settings).length === 0) {
      settings.width = defaultWidth;
      settings.height = defaultHeight;
      settings.timeFrame = defaultTimeFrame;
    }
    yield put(
      getDashboardSettingsSuccess({
        settings,
        controls: controlsAddedIndexKey,
        backgroundImageUrl,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(getDashboardSettingsError('Dashboard settings load failed'));
  }
}

function* saveDashboardSettings({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const {
      width,
      height,
      timeFrame,
      dashboardBackgroundImageData,
      controlBgImage,
    } = payload;
    const settings = {
      width: Number(width),
      height: Number(height),
      timeFrame: Number(timeFrame),
    };

    if (controlBgImage) {
      settings.controlBgImage = controlBgImage;
    }

    const { dashboardSettings, dashboardControls } = yield select(
      (state) => state.Dashboard
    );

    let controls;
    if (settings.width < dashboardSettings.width) {
      controls = yield updateControlsPosition(
        controls || dashboardControls,
        'width',
        settings.width
      );
    }
    if (settings.height < dashboardSettings.height) {
      controls = yield updateControlsPosition(
        controls || dashboardControls,
        'height',
        settings.height
      );
    }
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
    const params = {
      settings,
      controls,
    };
    if (dashboardBackgroundImageData) {
      params.backgroundImageData = dashboardBackgroundImageData;
    }
    const response = yield call(requests.patchRequest, url, params); // If no control update was done (e.g. only timeFrame was changed), then it will be undefined, thus no update to controls will be done
    yield put(saveDashboardSettingsSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(saveDashboardSettingsError('Save Dashboard settings failed'));
  } finally {
    yield put(saveDashboardSettingsCleanState());
  }
}

function* addControl({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const {
      name,
      type,
      color,
      unit,
      channel,
      priority,
      onColor,
      offColor,
      fontSize,
      isShowDateTime,
      x,
      y,
      width,
      height,
    } = payload;
    const newControl = {
      name: name,
      type: type,
      color: color,
      unitId: unit,
      channelData: {
        id: channel,
      },
      priority,
      id: new Date().valueOf(), // add "id" field for uniqueness
      fontSize,
      isShowDateTime,
      indexKey: new Date().valueOf(),
      x: parseInt(x),
      y: parseInt(y),
      width: parseInt(width),
      height: parseInt(height),
    };

    if (onColor && offColor) {
      newControl.onColor = onColor;
      newControl.offColor = offColor;
    }

    const currentControls = yield select(
      (state) => state.Dashboard.dashboardControls
    );
    const controls = [...currentControls, newControl];
    // Do API call here
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
    const response = yield call(requests.patchRequest, url, { controls });
    yield put(addControlSuccess(response));
    toastSuccess(TextConstants.Dashboard.ControlAdded);
  } catch (e) {
    console.log(e);
    yield put(addControlError('Add control failed'));
  } finally {
    yield put(addControlCleanState());
  }
}

function prepareControls(payload, currentControls) {
  const {
    name,
    type,
    color,
    unit,
    channel,
    x,
    y,
    height,
    width,
    id,
    priority,
    onColor,
    offColor,
    fontSize,
    isShowDateTime,
    indexKey,
  } = payload;

  const updatedControl = {
    name: name,
    type: type,
    color: color,
    unitId: unit,
    channelData: {
      id: channel,
    },
    x: x || 0,
    y: y || 0,
    fontSize: fontSize,
    isShowDateTime: isShowDateTime,
    indexKey: indexKey,
  };

  // only add height and width if available
  if (height && width) {
    updatedControl.height = height;
    updatedControl.width = width;
  }

  if (![null, undefined].includes(priority)) {
    updatedControl.priority = priority;
  }

  if (onColor && offColor) {
    updatedControl.onColor = onColor;
    updatedControl.offColor = offColor;
  }

  // find the index of control to be edited
  const findIndexToEdit = currentControls.findIndex(
    (control) => control.id === id
  );

  let controls;
  if (findIndexToEdit !== -1) {
    controls = [...currentControls];
    controls[findIndexToEdit] = {
      ...controls[findIndexToEdit],
      ...updatedControl,
    };
  }

  return controls;
}

function* editControl({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    const currentControls = yield select(
      (state) => state.Dashboard.dashboardControls
    );

    const controls = prepareControls(payload, currentControls);

    // // Do API call here
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
    const response = yield call(requests.patchRequest, url, { controls });
    yield put(editControlSuccess(response));

    toastSuccess(TextConstants.Dashboard.ControlUpdated);
  } catch (e) {
    console.log(e);
    toastError(e.message);
    yield put(editControlError());
  }
}

function* editControlPositionAndSize({ payload }) {
  const currentControls = yield select(
    (state) => state.Dashboard.dashboardControls
  );

  const controls = prepareControls(payload, currentControls);

  // update the controls immediately if its dragged or resized
  yield put(updateDraggedControl(controls));

  try {
    // check valid token
    yield checkValidToken();
    // Do API call here
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
    yield call(requests.patchRequest, url, { controls });
    yield put(editControlPositionAndSizeSuccess());
  } catch (e) {
    console.log(e);
    toastError(TextConstants.Dashboard.UpdatePositionOrSizeFailedErrMsg);
    yield put(editControlPositionAndSizeError());
  } finally {
    yield delay(1500);
    yield put(editControlPositionAndSizeCleanState());
  }
}

function* deleteControl({ payload }) {
  try {
    // check valid token
    yield checkValidToken();

    // extract id from the payload
    const { id } = payload;
    // get current controls from the store
    const currentControls = yield select(
      (state) => state.Dashboard.dashboardControls
    );
    // find the index of control to be deleted
    const findIndexToDelete = currentControls.findIndex(
      (control) => control.id === id
    );
    if (findIndexToDelete !== -1) {
      // filter the remaining controls
      const remainControls = currentControls.filter(
        (_, index) => index !== findIndexToDelete
      );
      // DB call here
      const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
      const response = yield call(requests.patchRequest, url, {
        controls: remainControls,
      });
      yield put(deleteControlSuccess(response));
    } else {
      yield put(deleteControlError('No control found with current selection!'));
    }
  } catch (e) {
    console.log(e);
    yield put(deleteControlError('Failed to delete the control!'));
  }
}

/**
 * Whenever dashboard width or height are changed, if the new values are lower than the previous ones, then
 * the controls need to be checked to see if any one of them would be outside the new dashboard size. If so, the x or y
 * position will be updated to be kept inside.
 * @param {Array{}} controlList The current controls
 * @param {String} parameter The parameter to check: width or height
 * @param {Number} limit The value of the above parameter
 * @returns A new control list with the position clamped to the new dashboard limits
 */
function* updateControlsPosition(controlList, parameter, limit) {
  const newList = [];
  for (const control of controlList) {
    const controlData = { ...control };
    if (parameter === 'width') {
      const controlWidth = controlData.width ?? minDimensions.width;
      const endX = controlData.x + controlWidth;
      if (endX > limit) {
        controlData.x -= endX - limit;
      }
      // TODO: Clamp pin destination's position
    } else {
      const controlHeight = controlData.height ?? minDimensions.height;
      const endY = controlData.y + controlHeight;
      if (endY > limit) {
        controlData.y -= endY - limit;
      }
    }
    newList.push(controlData);
  }
  return newList;
}

function* setPinDestination({ payload }) {
  const { controlId, destinationPoint } = payload;
  const controls = yield select((state) => state.Dashboard.dashboardControls);
  const newControls = controls.map((currentControl) => {
    const newControl = { ...currentControl };
    if (newControl.id === controlId) {
      newControl.destination = destinationPoint;
    }
    return newControl;
  });
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DASHBOARD}`;
    const response = yield call(requests.patchRequest, url, {
      controls: newControls,
    });
    yield put(setPinDestinationSuccess(response));
    toastSuccess(TextConstants.Dashboard.ControlUpdated);
  } catch (e) {
    console.log(e);
    toastError(TextConstants.Dashboard.SetPinPositionFailedErrMsg);
    yield put(setPinDestinationError());
  }
}

export function* watchDashboard() {
  yield takeLeading(
    dashboardActions.GET_DASHBOARD_SETTINGS,
    getDashboardSettings
  );
  yield takeLeading(dashboardActions.ADD_CONTROL, addControl);
  yield takeLeading(dashboardActions.EDIT_CONTROL, editControl);
  yield takeLeading(dashboardActions.DELETE_CONTROL, deleteControl);
  yield takeLeading(
    dashboardActions.SAVE_DASHBOARD_SETTINGS,
    saveDashboardSettings
  );
  yield takeLatest(
    dashboardActions.EDIT_CONTROL_POSITION_AND_SIZE,
    editControlPositionAndSize
  );
  yield takeLeading(dashboardActions.SET_PIN_DESTINATION, setPinDestination);
}

export default function* rootSaga() {
  yield all([fork(watchDashboard)]);
}
