export const LAST_SEQUENCE_ACTION = {
  GET_NEXT_SEQUENCE_VALUE: 'GET_NEXT_SEQUENCE_VALUE',
  GET_NEXT_SEQUENCE_VALUE_SUCCESS: 'GET_NEXT_SEQUENCE_VALUE_SUCCESS',
  GET_NEXT_SEQUENCE_VALUE_FAILED: 'GET_NEXT_SEQUENCE_VALUE_FAILED',
};
// =========================================================================
export const getNextSequenceValue = (data) => ({
  type: LAST_SEQUENCE_ACTION.GET_NEXT_SEQUENCE_VALUE,
  payload: data,
});
